<div class="container-layout">
  <div class="container-fluid container-content">
    <div class="marquee">
      <i class="far fa-check marquee-icon"></i>
      <h1 class="marquee-heading">{{'apr.reconfirmationPage.header' | translate}}</h1>
      <h2 class="marquee-message">
        <p>
          {{'apr.reconfirmationPage.bodyText1' | translate}}
        </p>

        <p>
          {{'apr.reconfirmationPage.bodyText2' | translate}}
        </p>
      </h2>
      <p>
        {{'apr.reconfirmationPage.closeWindow' | translate}}
      </p>
    </div>
  </div>
</div>

