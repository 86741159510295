import { environment } from 'environments/environment';
import { app_name, build_number, build_date, branch_name, last_commit } from '../assets/version.json';
import { version } from '../../package.json';

const URL_ONYX = 'https://www.onyxcentersource.com/';

// UI configurable
  // Card 1
export const OFFICE_ID = 'officeId';
export const AGENCY_ID_TYPE = 'agencyIdType';
export const VCC_YN = 'vccYn';
export const AGENCY_ID = 'agencyId';
export const CLIENT_NAME = 'clientName';
export const EMAIL = 'email';
export const NO_IATA_CHECK = 'noIataCheck';
export const PRIVACY_CHECK = 'privacyCheck';
export const COMMUNICATION_EMAIL_CHECK = 'communicationEmailCheck';
export const TERMS_COND_ADDITIONAL_CHECK = 'tcAdditionalCheck';

  // expiration page
export const EXPIRATION_GDS = 'expirationGds';

export abstract class Constants {
  // APR Services
  public static readonly TOKEN_KEY = 'token';
  public static readonly APR_APP = 'apr';
  public static readonly VERSION = version;
  public static readonly USER_ID = 'userId';
  public static readonly IS_OFAC_SWIFT_CORRELATION_ID = 'isOfacSwiftCorrelationId';
  public static readonly IS_OFAC_ROUTING_CORRELATION_ID = 'isOfacRoutingCorrelationId';
  public static readonly LANG_KEY = 'language';

  public static readonly VERSION_APP_NAME = app_name;
  public static readonly VERSION_BUILD_NUMBER = build_number;
  public static readonly VERSION_BUILD_DATE = build_date;
  public static readonly VERSION_BRANCH_NAME = branch_name;
  public static readonly VERSION_LAST_COMMIT = last_commit;


  public static readonly URL_APR_PATH = 'apr/';
  public static readonly URL_GET_PARTNER_ID = environment.aprServiceApiUrl + 'apr/partner/retrieve';
  public static readonly URL_GET_PARTNER_BY_NAME_AND_VCC = environment.aprServiceApiUrl + 'apr/partner/retrieve/vcc';
  public static readonly URL_GET_APR_AGENCY_INFO = environment.aprServiceApiUrl + 'apr/agency/retrieve';
  public static readonly URL_IS_APR_AGENCY_ACTIVATED = environment.aprServiceApiUrl + 'apr/agency/activated';
  public static readonly URL_POST_APR_CREATE_REQUEST = environment.aprServiceApiUrl + 'apr/createRequest';
  public static readonly URL_POST_APR_UPSERT_REQUEST = environment.aprServiceApiUrl + 'apr/upsertRequest';
  public static readonly URL_POST_IS_IBAN_VALID = environment.aprServiceApiUrl + 'iban/validate';
  public static readonly URL_POST_GET_TERMS_AND_CONDITIONS_ACTIVE = environment.aprServiceApiUrl + 'apr/tc/retrieve/active';
  public static readonly URL_POST_GET_TERMS_AND_CONDITIONS_BY_ID = environment.aprServiceApiUrl + 'apr/tc/retrieve';

  public static readonly URL_MAINTENANCE = environment.aprServiceApiUrl + 'maintenance';


  // Common Services
  public static readonly URL_GET_COUNTRIES = environment.commonServiceApiUrl + 'v1/country';
  public static readonly URL_GET_STATES_BY_COUNTRY = environment.commonServiceApiUrl + 'v1/state/country/{country}';
  public static readonly URL_GET_CURRENCIES = environment.commonServiceApiUrl + 'v1/currency';
  public static readonly URL_GET_BANK_RULES_BY_COUNTRY = environment.commonServiceApiUrl + 'v1/bankrule/country/{country}';
  public static readonly URL_POST_APR_BANK_RULES_BY_COUNTRY = environment.commonServiceApiUrl + 'v1/bankrule/bycountry/{country}';
  public static readonly URL_POST_IS_OFAC_ROUTING_VALID = environment.commonServiceApiUrl + 'v1/ofacbank/ofac/routing/';
  public static readonly URL_POST_IS_OFAC_SWIFT_VALID = environment.commonServiceApiUrl + 'v1/ofacbank/ofac/swift/';

  // REGISTRATION STATUES
  public static readonly STEP_REGISTRATION = 'registration';
  public static readonly STEP_PROFILE = 'profile';
  public static readonly STEP_PAYMENTS = 'payments';
  public static readonly STEP_USERCOMPLETE = 'usercomplete';
  public static readonly STEP_OFACCONUNTRY = 'ofac-country';

  // AgencyId status
  public static readonly ACTIVE = 'active';
  public static readonly DEACTIVATED = 'deactivated';
  public static readonly PARTIAL = 'partial';
  public static readonly DUPLICATED = 'duplicated';
  public static readonly IATA_TAPE_ERROR = 'iataTapeError';

  // upsert request bad responses
  public static readonly PARTNERID_NOT_FOUND = 'PARTNERID_NOT_FOUND';
  public static readonly OFFICEID_ALREADY_VCC_SIGNED = 'OFFICEID_ALREADY_VCC_SIGNED';
  public static readonly OFFICEID_REGISTRATION_NOT_FOUND = 'OFFICEID_REGISTRATION_NOT_FOUND';
  public static readonly AGENCYCODE_ALREADY_VCC_SIGNED = 'AGENCYCODE_ALREADY_VCC_SIGNED';
  public static readonly AGENCYCODE_REGISTRATION_NOT_FOUND = 'AGENCYCODE_REGISTRATION_NOT_FOUND';
  public static readonly SWITCH_TO_VCC_N_NOT_ALLOW = 'SWITCH_TO_VCC_N_NOT_ALLOW';


  // OTHERS
  public static readonly URL_CUSTOMER_SUPPORT = URL_ONYX + 'need-assistance/?utm_source=customer/portal&utm_medium=referral';

  /*
   * u00C0: Unicode Character 'LATIN CAPITAL LETTER A WITH GRAVE'
   * u00ff: Unicode Character 'LATIN SMALL LETTER Y WITH DIAERESIS'
   */
  // tslint:disable-next-line:max-line-length
  public static readonly LATIN_PATTERN = '^[A-z\ \u00C0-\u00ff\s\'\.,-\/#!$%\^&\*;:{}=\-_`~()ºª0123456789¡¢£¤¥¦§¨©ª«¬­®¯°±²³´µ¶·¸¹º»¼½¾¿]+$';

  public static readonly countriesWithStates: string[] = ['AU', 'CA', 'US'];

  public static readonly ERROR_TOKENPWD_INVALID = 'ERROR_TOKENPWD_0001';
  public static readonly ERROR_TOKENPWD_EXPIRED = 'ERROR_TOKENPWD_0002';

  public static readonly APR_ACCOUNT_TYPES = [
    { code: 'C', name: 'Checking account' },
    { code: 'S', name: 'Savings account' },
  ];

  public static readonly NO_IATA = 'NOIATA';


  // Field types
  public static readonly INPUT_TYPE = 'I';
  public static readonly CHECKBOX_TYPE = 'C';
  public static readonly DROPDOWN_TYPE = 'D';
  public static readonly READONLY_TYPE = 'R';

  // Font types
  public static readonly NORMAL_FONT = 'normal';
  public static readonly ITALIC_FONT = 'italic';

  // i18n
  public static LANG_DEFAULT = { code: 'en', name: 'English', i18n: 'US' };

  public static readonly APR_LANGUAGES = [
    { code: 'en', name: 'English', i18n: 'US' },
    { code: 'es', name: 'Español', i18n: 'ES' },
    { code: 'fr', name: 'Français', i18n: 'FR' },
    { code: 'de', name: 'Deutsch', i18n: 'DE' },
    { code: 'pt', name: 'Português', i18n: 'PT' },
    { code: 'it', name: 'Italiano', i18n: 'IT' },
    { code: 'ja', name: '日本語', i18n: 'JP' },
    { code: 'zh', name: '普通话', i18n: 'CN' },
  ];

  // maximum number of office IDs
  public static readonly MAX_OFFICE_IDS_ALLOWED = 100;
}
