import { FormGroup } from '@angular/forms';

export function validationAgencyFormat(fg: FormGroup) {

  if (fg.value !== null) {
    const agencyId = fg.value;
    const lastDigit = Number(agencyId.substr(agencyId.length - 1));
    const formule = ((Number(agencyId.substr(0, 7)) ) % 7);

    return lastDigit !== formule ? { formatAgencyInvalid: {value: fg.value} } : null;
  }

}
