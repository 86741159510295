import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from '../../app.constants';

@Injectable({
  providedIn: 'root',
})
export class RefreshTokenInterceptor implements HttpInterceptor {

  isUserLoggedIn: boolean;

  constructor(
    private jwtHelper: JwtHelperService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(map(event => {
      if (event instanceof HttpResponse) {
        const updatedToken = event.headers.get('x-amzn-remapped-authorization');

        const decodedToken = this.jwtHelper.decodeToken(updatedToken);

        if (decodedToken) {
          const app = decodedToken.sub;

          if (Constants.APR_APP.indexOf(app) !== -1) {
            localStorage.setItem(Constants.TOKEN_KEY, updatedToken);
          }
        }
      }
      return event;
    }));
  }

}
