import { HttpResponse } from '@angular/common/http';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { AprService } from '@app/services/apr.service';
import { Observable } from 'rxjs';
import { debounceTime, map, switchMap, take } from 'rxjs/operators';

export class OfacSwiftValidator {

  static createValidator(aprService: AprService): AsyncValidatorFn {

    return (control: AbstractControl): Observable<ValidationErrors> => {
      return control.valueChanges.pipe(
        debounceTime(500),
        take(1),
        switchMap(_ =>
          aprService.isOfacSwift(control.value).pipe(
            map((response: HttpResponse<Boolean>) => {
              return response.body ? { ofacSwiftInvalid: true } : null;
            })
          )
        )
      );
    };

  }

}
