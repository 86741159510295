import { ReplacePipe } from './replace.pipe';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    ReplacePipe,
  ],
  providers: [
    ReplacePipe,
  ],
  exports: [
    ReplacePipe,
  ],
})
export class PipesModule { }
