<div (window:beforeunload)="onBeforeUnload($event)">
  <div
    *ngIf="!showEditAlertPage && !showCompletePage && !showSessionExpiredPage"
    class="container-fluid container-content">
    <div class="row">
      <div class="offset-xl-3 col-xl-6 offset-lg-2 col-lg-8">
        <!--i18n-->
        <div class="offset-lg-10">
          <a class="nav-link dropdown-toggle text-right pr-0" data-toggle="dropdown" href="#" role="button"
            aria-haspopup="true" aria-expanded="false">
            <i class="far fa-globe-americas"></i> {{activeLang}}
          </a>
          <div class="dropdown-menu">
            <div *ngFor="let item of languageList">
              <a class="dropdown-item" (click)="useLanguage(item.code, true)">{{item.name}}</a>
            </div>
          </div>
        </div>
        <!--i18n-->
      </div>
    </div>
    <div class="row">
      <div class="offset-xl-3 col-xl-6 offset-lg-2 col-lg-8">
        <div class="card">
          <div class="card-header">
            <h2 class="card-title">{{'apr.registrationUpdate.header' | translate}}</h2>
          </div>
          <div class="card-body">
            <p>
              {{'apr.registrationUpdate.bodyText1' | translate}}
            </p>
            <p class="mb-0">
              {{'apr.registrationUpdate.b3_1' | translate}}
              <a target=_blank href="{{this.userGuideLink}}" (click)="sendGAInfo('g')">{{'apr.registrationUpdate.b3_2' |
                translate}}<i class='far fa-external-link'></i></a>
              {{'apr.registrationUpdate.b3_2_1' | translate}}
              <a target=_blank href="{{this.needAssitanceLink}}">{{'apr.registrationUpdate.b3_3' | translate}}<i
                  class='far fa-external-link'></i></a>
            </p>
          </div>
          <div *ngIf="this.faqLink" class="card-footer">
            <span [innerHTML]="'apr.registrationUpdate.faq' | translate"></span><a target=_blank href="{{this.faqLink}}"
              (click)="sendGAInfo('f')">{{'apr.registrationUpdate.faqLink' | translate}}<i
                class='far fa-external-link'></i></a>
            {{'apr.registrationUpdate.faqForAwnswer' | translate}}
          </div>
        </div>
        <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">

          <ta-agency-edit-identity-dynamic id="step1card" 
            [registrationForm]="registrationForm"
            [partnerIdInfo]="partnerIdInfo"
            (showEditAlertPage)="setShowEditAlertPage()"
            (showCompletePage)="setShowCompletePage()"
            (aprSave)="setAprSave($event)"
            (agencyCode)="setAgencyCode($event)"
            (termsAndConditionsAdditionalSignedEmit)="setTermsAndConditionsAdditionalSigned($event)">
          </ta-agency-edit-identity-dynamic>
        </form>

      </div>
    </div>
  </div>
</div>

<!-- Complete Page -->
<div *ngIf="showCompletePage && !showSessionExpiredPage">
  <ta-agency-registration-complete [emailContact]=""></ta-agency-registration-complete>
</div>

<!-- Session Expiration Page -->
<div *ngIf="showSessionExpiredPage">
  <ta-agency-registration-session-expiration [partnerIdInfo]="partnerIdInfo" [sessionExpiredTime]="sessionExpiredTime"
    (reloadRegistration)="reloadRegistration()">
  </ta-agency-registration-session-expiration>
</div>

<!-- Upsert registration error Page -->
<div *ngIf="showEditAlertPage && !showSessionExpiredPage">
  <ta-agency-edit-alert [agencyCode]="agencyCode"></ta-agency-edit-alert>
</div>