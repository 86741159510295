<div class="container-layout">
  <div class="container-fluid container-content">
    <div class="marquee">
      <i class="far fa-check marquee-icon"></i>
      <h1 class="marquee-heading"> {{'apr.completePage.header' | translate}}</h1>
      <h2 class="marquee-message">
        <p>
          {{'apr.completePage.bodyText1' | translate}}
        </p>

        <p [innerHtml]="'apr.completePage.bodyText2' | translate: {email: emailContact}">
        </p>
      </h2>
      <p>
        {{'apr.completePage.closeWindow' | translate}}
      </p>
    </div>
  </div>
</div>

