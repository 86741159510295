import { FormGroup, ValidatorFn } from '@angular/forms';


export function editRegistrationFieldsValidator(formGroup: FormGroup) {
  const officeId = formGroup.get('officeId');
  const agencyCode = formGroup.get('agencyId');

  if (officeId && officeId.value ) {
    return null;
  }

  if (agencyCode && agencyCode.value ) {
    return null;
  }

  return { requiredFields: true };

}
