<div class="card">
  <div class="card-header">
    <h2 class="card-title">{{'apr.registrationIdentity.header' | translate}}</h2>
  </div>
  <div class="card-body">
    <form [formGroup]="identityForm">
      <div *ngFor="let item of fieldList">
        <div *ngIf="!isComplete" class="form-group row" [ngClass]="{ 'form-group-invalid': isFieldValid(item.fieldName) }">
          <!-- labels -->
          <label *ngIf="hasLabel(item) && !isReadOnlyType(item.type) && item.fieldName != 'vccYn'" 
            class="col-form-label col-lg-3">
            {{item.keyi18n | translate}}
          </label>

          <label *ngIf="hasLabel(item) && !isReadOnlyType(item.type) && item.fieldName == 'vccYn'" 
            class="col-form-label col-lg-3"
            tooltip="{{'apr.registrationIdentity.vccTooltip' | translate}}" 
            placement="left">
            {{item.keyi18n | translate}}
          </label>


          <!-- controls -->
          <div *ngIf="isDropdownType(item.type) && item.fieldName == 'agencyIdType'" class="col-lg-9">
            <select
                    id=agencyIdTypeInput
                    class="form-control"
                    formControlName={{item.fieldName}}
                    name={{item.fieldName}}
                    (change)="onChangeAgencyId()"
                    [ngClass]="{ 'is-invalid': (isAgencyIdTypeInvalid() && agencyId.touched && !this.isNoIATA) }">
              <option [ngValue]="null" disabled>{{item.placeholder | translate}}</option>
              <option *ngFor="let type of item.values"
                      [ngValue]="type"
                      [style.font-style]="changeFontStyle(type.code)">
                      {{type.display | translate}}</option>
            </select>
          </div>

          <div *ngIf="isDropdownType(item.type) && item.fieldName == 'vccYn'" class="col-lg-9">
            <select
                    id=vccYnInput
                    class="form-control"
                    formControlName={{item.fieldName}}
                    name={{item.fieldName}}
                    (change)="onChangeVcc()"
                    [ngClass]="{ 'is-invalid': (isVccYnInvalid() && vccYn.touched) }">
              <option [ngValue]="null" disabled>{{item.placeholder | translate}}</option>
              <option *ngFor="let type of item.values"
                      [ngValue]="type"
                      [style.font-style]="changeFontStyle(type.code)">
                      {{type.display | translate}}</option>
            </select>
          </div>

          <div *ngIf="isCheckboxType(item.type)" class="offset-lg-3 col-lg-9" [ngClass]="{ 'form-group-invalid': (isFieldValid(noiatacheckConst)) }">
            <div *ngIf="isNoIATA">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="noIataCheck" formControlName="noIataCheck">
                <label class="custom-control-label" for="noIataCheck"><i>{{item.keyi18n | translate}}</i></label>
              </div>
            </div>
          </div>

          <div *ngIf="isInputType(item.type) && !item.readonly" class="col-lg-9" [ngClass]="{'offset-lg-3': (item.fieldName).indexOf(agencyIdConst) !== -1 }">
            <div *ngIf="(item.fieldName).indexOf(agencyIdConst) === -1">
              <input
                  type="text" taNoWhitespace class="form-control"
                  placeholder="{{item.placeholder | translate}}"
                  formControlName="{{item.fieldName}}"
                  name="{{item.fieldName}}"
                  [ngClass]="{ 'is-invalid': isFieldValid(item.fieldName) }"
                  (keyup)="(item.fieldName.indexOf(officeIdConst) !== -1) && setToUpperCase(officeId)"
                  (blur)="((item.fieldName.indexOf(officeIdConst) !== -1) && removeWhiteSpaces(officeId)) || sendGAInfo(item.fieldName,item.fieldName+'Invalid')"/>
              <small *ngIf="item.fieldName.indexOf(officeIdConst) !== -1" class="form-text text-muted">{{item.formatMsg | translate}}</small>
            </div>
            <div *ngIf="(item.fieldName).indexOf(agencyIdConst) !== -1">
              <input
                  id="agency-id-input"
                  type="text"
                  class="form-control"
                  placeholder="{{ (isNoIATA ? this.agencyTypeDisplay : item.placeholder) | translate | replace: {type: this.agencyTypeDisplay} }}"
                  formControlName={{item.fieldName}}
                  name={{item.fieldName}}
                  [ngClass]="{ 'is-invalid': agencyId.touched && agencyId.errors && !this.isNoIATA }"
                  (blur)="sendGAInfo(item.fieldName,item.fieldName+'Invalid')"/>
            </div>
          </div>

          <div *ngIf="isReadOnlyType(item.type)" class="form-control-plaintext">
            <label  class="col-form-label col-lg-3">{{item.keyi18n | translate}}</label>
              <span class="col-lg-1" *ngIf="item.fieldName.indexOf(officeIdConst) !== -1">{{partnerIdInfo.details.uniqueId}}</span>
              <span class="col-lg-1" *ngIf="item.fieldName.indexOf(clientNameConst) !== -1">{{partnerIdInfo.details.clientName}}</span>
          </div>

          <!-- Error msg management -->
          <span *ngIf="identityForm.controls[item.fieldName] && item.fieldName.indexOf(agencyIdTypeConst) === -1 && item.fieldName.indexOf(agencyIdConst) === -1" class="offset-lg-3 col-lg-9">
             <!-- maxlengthInvalid -->
            <span class="invalid-feedback" *ngIf="identityForm.controls[item.fieldName].hasError('required') || (!identityForm.controls[item.fieldName].hasError('partialError') && !identityForm.controls[item.fieldName].hasError('duplicatedError') && !identityForm.controls[item.fieldName].hasError('maxlengthInvalid'))">
              <span *ngIf="item.errorMsg">{{getError(item, 'required') | translate}}</span>
            </span>
            <span class="invalid-feedback" *ngIf="identityForm.controls[item.fieldName].hasError('partialError')">
              <span *ngIf="item.errorMsg" [innerHtml] = "getError(item, 'partial') | translate | replace: {officeIdListToString: officeIdListToString, URL_CUSTOMER_SUPPORT: customerSupportLink}"></span>
            </span>
            <span class="invalid-feedback" *ngIf="identityForm.controls[item.fieldName].hasError('duplicatedError')">
              <span *ngIf="item.errorMsg" [innerHtml] = "getError(item, 'duplicated') | translate | replace: {officeIdListToString: officeIdListToString}"></span>
            </span>
            <span class="invalid-feedback" *ngIf="(identityForm.controls[item.fieldName].hasError('maxlengthInvalid'))">
              <span *ngIf="item.errorMsg" [innerHtml] = "getError(item, 'maxLength') | translate"></span>
            </span>
          </span>

          <span *ngIf="identityForm.controls[item.fieldName] && (item.fieldName.indexOf(agencyIdTypeConst) !== -1 || item.fieldName.indexOf(agencyIdConst) !== -1)" class="offset-lg-3 col-lg-9">
            <span class="invalid-feedback" *ngIf="identityForm.controls[item.fieldName].hasError('required')">
              <span *ngIf="item.errorMsg">{{getError(item, 'required') | translate}}</span>
            </span>
            <span class="invalid-feedback" *ngIf="identityForm.controls[item.fieldName].hasError('iataTapeError')">
              <span *ngIf="item.errorMsg" [innerHtml] = "getError(item, 'iataTape') | translate"></span>
            </span>
            <span class="invalid-feedback" *ngIf="((identityForm.controls[item.fieldName].hasError('minlength')) || (identityForm.controls[item.fieldName].hasError('maxlength')) || (identityForm.controls[item.fieldName].hasError('formatAgencyInvalid')))">
              <span *ngIf="item.errorMsg">{{getError(item, 'formatAgencyInvalid') | translate}}</span>
            </span>
          </span>

        </div> <!-- end !isComplete -->

        <!-- Complete - Readonly -->
        <div *ngIf="isComplete" class="form-group row">
          <div *ngIf="isReadOnlyType(item.type)" class="form-control-plaintext">
            <label  class="col-form-label col-lg-3">{{item.keyi18n | translate}}</label>
              <span class="col-lg-1" *ngIf="item.fieldName.indexOf(officeIdConst) !== -1">{{partnerIdInfo.details.uniqueId}}</span>
              <span class="col-lg-1" *ngIf="item.fieldName.indexOf(clientNameConst) !== -1">{{partnerIdInfo.details.clientName}}</span>
          </div>

          <div *ngIf="isInputType(item.type) && (item.fieldName).indexOf(agencyIdConst)" class="form-control-plaintext">
            <label  class="col-form-label col-lg-3">{{item.keyi18n | translate}}</label>
            <span *ngIf="item.fieldName.indexOf(emailConst) === -1">{{identityForm.controls[item.fieldName].value}}</span>
            <span *ngIf="item.fieldName.indexOf(emailConst) !== -1" class="col-lg-1"><a target=_blank href="mailto:{{email.value}}">{{email.value}}</a></span>
          </div>

          <div *ngIf="isDropdownType(item.type)" class="form-control-plaintext" >
            <div *ngIf="item.fieldName == 'agencyIdType' && agencyId">
              <div *ngIf="agencyId.value">
                <label class="col-form-label col-lg-3">{{this.agencyTypeDisplay | translate}}</label>
                <span class="col-lg-1">{{agencyId.value}}</span>
              </div>
              <div *ngIf="!agencyId.value">
                <label class="col-form-label col-lg-4">{{this.agencyTypeDisplay | translate}}</label>
              </div>
            </div>

            <div *ngIf="item.fieldName == 'vccYn'">
                <label class="col-form-label col-lg-3">{{item.keyi18n | translate}}</label>
                <span class="col-lg-1">{{this.vccYnDisplay | translate}}</span>
            </div>
          </div>

        </div> <!-- end isComplete -->

      </div> <!-- loop -->

      <!-- Agreements section -->
            <!-- Agreements section -->
            <div class="form-group row mb-0" [ngClass]="{ 'form-group-invalid': (isFieldValid(privacyCheckConst) || isFieldValid(tcAdditionalCheckConst)) }">
              <label for="agreement-text" class="col-lg-3 col-form-label">{{'apr.registrationIdentity.agreementsLabel' | translate}}</label>
              <div class="col-lg-9">
                <p class="form-control-plaintext" [innerHTML]="'apr.registrationIdentity.agreementsLink' | translate | replace: {url: termsAndConditionsUrl}"></p>

                <div id="agreement-text" *ngIf="!isComplete">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="privacyCheck" formControlName="privacyCheck"
                    (blur)="sendGAInfo('privacyCheck','privacyCheckInvalid')">
                    <label class="custom-control-label" for="privacyCheck">{{agreementsPrivacyPolicy | translate | replace: {partnerName: partnerName} }}</label>
                  </div>
                  <div class="form-group custom-control custom-checkbox form-group-invalid:false">
                    <input type="checkbox" class="custom-control-input" id="communicationEmailCheck" formControlName="communicationEmailCheck"
                    (blur)="sendGAInfo('communicationEmailCheck','communicationEmailCheckInvalid')">
                    <label class="custom-control-label" for="communicationEmailCheck">{{agreementsReceiveEmail | translate}}</label>
                  </div>
                  <div class="custom-control custom-checkbox" *ngIf="this.hasTcAdditional">
                    <input type="checkbox" class="custom-control-input" id="tcAdditionalCheck" formControlName="tcAdditionalCheck"
                    (blur)="sendGAInfo('tcAdditionalCheck','tcAdditionalCheckInvalid')">
                    <label class="custom-control-label"
                           for="tcAdditionalCheck">{{'apr.registrationIdentity.agreementsTcAdditional_1' | translate}}<a href={{termsAndConditionsAdditionalUrl}} target=_blank>{{'apr.registrationIdentity.agreementsTcAdditional_2' | translate}}<i class='far fa-external-link'></i></a> {{'apr.registrationIdentity.agreementsTcAdditional_3' | translate | replace: {partnerName: partnerName} }} </label>
                  </div>
                </div>
                <div class="invalid-feedback">
                  <span>{{'apr.registrationIdentity.agreementsErrorText' | translate}}</span>
                </div>

               <ul *ngIf="isComplete" class="fa-ul fa-ul-align-left mb-0">
                  <li>
                    <span class="fa-li">
                      <i class="far fa-check" aria-hidden="true" title="Agreed"></i>
                      <span class="sr-only">{{'apr.registrationIdentity.agreedSrOnly' | translate}}</span>
                    </span>
                    <span>{{agreementsPrivacyPolicy | translate | replace: {partnerName: partnerName} }}</span>
                  </li>
                  <li *ngIf="communicationEmailCheck.value">
                    <span class="fa-li">
                      <i class="far fa-check" aria-hidden="true" title="Agreed"></i>
                      <span class="sr-only">{{'apr.registrationIdentity.agreedSrOnly' | translate}}</span>
                    </span>
                    <span>{{'apr.registrationIdentity.agreementsReceiveEmail' | translate}}</span>
                  </li>
                  <li *ngIf="tcAdditionalCheck && tcAdditionalCheck.value">
                    <span class="fa-li">
                        <i class="far fa-check" aria-hidden="true" title="Agreed"></i>
                        <span class="sr-only">{{'apr.registrationIdentity.agreedSrOnly' | translate}}</span>
                    </span>
                    <span>{{'apr.registrationIdentity.agreementsTcAdditional_1' | translate}}<a href={{termsAndConditionsAdditionalUrl}} target=_blank>{{'apr.registrationIdentity.agreementsTcAdditional_2' | translate}}<i class='far fa-external-link'></i></a> {{'apr.registrationIdentity.agreementsTcAdditional_3' | translate | replace: {partnerName: partnerName} }} </span>
                  </li>
                </ul>
                <br>
              </div>
            </div>

    </form>
  </div> <!-- card body -->

  <!-- Button Continue -->
  <div *ngIf="!isComplete" class="card-footer">
    <div class="row">
      <div class="offset-lg-8 col-lg-4">
        <button *ngIf="isValidating" type="button" class="btn btn-primary btn-block" disabled>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            {{'apr.registrationIdentity.verifyingSubmitBtnLabel' | translate}}
        </button>
        <button *ngIf="!isValidating" type="submit" class="btn btn-primary btn-block" (click)="onContinueClicked($event)"> {{'apr.registrationIdentity.continueSubmitBtnLabel' | translate}}</button>
        <div *ngIf="isFormValid()" class="form-group form-group-invalid mb-0">
          <div class="invalid-feedback">
            <span>{{'apr.registrationIdentity.submitErrorText' | translate}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>  <!-- card -->
