import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../../app.constants';

@Component({
  selector: 'ta-internal-server-error',
  templateUrl: './internal-server-error.component.html',
  styles: [
  ],
})
export class InternalServerErrorComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
