<ta-agency-registration *ngIf="!editMode"
    [showSessionExpiredPage]="showSessionExpiredPage"
    [sessionExpiredTime]= "sessionExpiredTime"
    [partnerIdInfo]="partnerIdInfo"
    (reloadAppComponent)="reloadAppComponent()"
    (moveToTop)="moveToTop($event)">
</ta-agency-registration>

<ta-agency-edit *ngIf="editMode"
    [showSessionExpiredPage]="showSessionExpiredPage"
    [sessionExpiredTime]= "sessionExpiredTime"
    [partnerIdInfo]="partnerIdInfo"
    (reloadAppComponent)="reloadAppComponent()"
    (moveToTop)="moveToTop($event)">
</ta-agency-edit>