import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TitleService } from '@core/services/title.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { PartnerResponse } from './models/partner-response.model';
import { AprService } from './services/apr.service';
import { environment } from 'environments/environment';
import { Constants } from './app.constants';

@Component({
  selector: 'ta-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewChecked {
  termsOfBusinessUrl: string;

  // componentTitle: string;
  username: string;
  isUserLoggedIn: boolean;

  language: string;

  bsModalRef: BsModalRef;

  expirationTitle: string;
  expirationContent: string;
  countDown: number;
  isExpModalOpen = false;

  partnerIdHash: string;
  affiliateIdHash: string;
  partnerId: string;
  otaId: string;
  gdsOk: Observable<boolean> = this.aprService.gdsOk$;
  partnerName: string;

  constructor(
    private translate: TranslateService,
    private titleService: TitleService,
    private cdRef: ChangeDetectorRef,
    private aprService: AprService
  ) {

    this.language = localStorage.getItem(Constants.LANG_KEY);

    if (this.language && Constants.APR_LANGUAGES.find(lang => lang.code === this.language)) {
      this.translate.use(this.language);
    } else {
      // this language will be used as a fallback when a translation isn't found in the current language
      this.translate.use('en');
      localStorage.setItem(Constants.LANG_KEY, 'en');
    }

    this.setGa4TrackingId();

  }

  ngOnInit(): void {
    this.titleService.setPageTitle();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  setGa4TrackingId() {
    if (environment.ga4TrackingId) {
      // register google tag manager
      const gTagManagerScript = document.createElement('script');
      gTagManagerScript.async = true;
      gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.ga4TrackingId}`;
      document.head.appendChild(gTagManagerScript);

      // register google analytics
      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
window.dataLayer = window.dataLayer || [];
function gtag() { dataLayer.push(arguments); }
`;
      document.head.appendChild(gaScript);
      // console.log('Added Google Analytics 4 script in head for ga4TrackingId:' + environment.ga4TrackingId);
    }
  }

}
