
import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[taNoWhitespace]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: NoWhitespaceDirective, multi: true },
  ],
})
export class NoWhitespaceDirective implements Validator {
  constructor() {}

  validate(control: AbstractControl): ValidationErrors {
    if (!control.value || control.value.trim() === '') {
      if (this.isControlRequired(control)) {
        return { required: true };
      }
    }
    return null;
  }

  isControlRequired = (control: AbstractControl): boolean => {
    if (!control) {
      return false;
    }

    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  };
}
