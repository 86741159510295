import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

// declare const gtag: (event: string, eventAction: string, eventData: {}) => {};
// declare var gtag: (...args: any) => {};
declare let gtag: any;

export const GTAG_ACTIONS = {
  channelPartnerLogin: 'channel_partner_login',

  startProfileCardTime: 'start_profile_card_time',
  startRegistrationCardTime: 'start_registration_card_time',
  startPaymentCardTime: 'start_payment_card_time',

  finishProfileCardTime: 'finish_profile_card_time',
  finishRegistrationCardTime: 'finish_registration_card_time',
  finishPaymentCardTime: 'finish_payment_card_time',

  spentTimeRegistrationCard: 'spentTimeRegistrationCard',
  spentTimeProfileCard: 'spentTimeProfileCard',
  spentTimePaymentCard: 'spentTimePaymentCard',

  fieldFrontendValidationError: 'field_frontend_validation_error',

  clickUserGuide: 'click_user_guide',
  clickSupportEmail: 'click_support_email',
  clickFaq: 'click_faq',
};

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  private channelPartnerSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>(null);
  public channelPartner$: Observable<string> =
    this.channelPartnerSubject.asObservable();

  private currentCardSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>(null);
  public currentCard$: Observable<string> =
    this.currentCardSubject.asObservable();

  constructor() {}

  public channelPartnerLogin(partnerName: string) {
    // console.log('register: ' + partnerName);
    this.channelPartnerSubject.next(partnerName);
    const ga_user_id = partnerName + '_' + new Date().getTime();
    const trackingId = environment.ga4TrackingId;

    if (typeof gtag === 'function') {
      gtag('js', new Date());
      gtag('config', trackingId,
        {
          'user_id': ga_user_id,
          'cookieDomain': environment.aprDomain,
          'cookieFlags': 'SameSite=None;Secure',
          'custom_map': {
            'dimension1': 'channel_partner',
            'dimension2': 'card',
            'metric1': 'start_profile_card_time',
            'metric2': 'finish_profile_card_time',
            'metric3': 'start_registration_card_time',
            'metric4': 'finish_registration_card_time',
            'metric5': 'start_payment_card_time',
            'metric6': 'finish_payment_card_time',
            'metric7': 'spentTimeRegistrationCard',
            'metric8': 'spentTimeProfileCard',
            'metric9': 'spentTimePaymentCard',
            'metric10': 'clickUserGuide',
            'metric11': 'clickSupportEmail',
            'metric12': 'clickFaq',
          },
        });

    } else {
      // console.log('gtag undefined: ' + typeof gtag);
    }

    this.eventEmitter(GTAG_ACTIONS.channelPartnerLogin, {});
    // @TODO console.log(' -> Register to GA Tag Manager: ' + trackingId + ' - ' + partnerName + ' - ' + ga_user_id);
  }

  // Time between the page is loaded and the card is showed
  public startProfileCard(timeSecs: number) {
    this.currentCardSubject.next('profile_card');
    this.eventEmitter(GTAG_ACTIONS.startProfileCardTime, {
      start_profile_card_time: timeSecs,
    });
  }
  // Time between the card is showed and the Continue button is clicked
  // 0 if card is not filled by the user
  public finishProfileCard(timeSecs: number) {
    this.currentCardSubject.next('profile_card');
    this.eventEmitter(GTAG_ACTIONS.finishProfileCardTime, {
      finish_profile_card_time: timeSecs,
    });
  }

  public spentTimeProfileCard(timeSecs: number) {
    this.currentCardSubject.next('profile_card');
    // console.log('spentTimeProfileCard', timeSecs);
    this.eventEmitter(GTAG_ACTIONS.spentTimeProfileCard, {
      spentTimeProfileCard: timeSecs,
    });
  }

  public startRegistrationCard(timeSecs: number) {
    this.currentCardSubject.next('registration_card');
    this.eventEmitter(GTAG_ACTIONS.startRegistrationCardTime, {
      start_registration_card_time: timeSecs,
    });
  }

  public finishRegistrationCard(timeSecs: number) {
    this.currentCardSubject.next('registration_card');
    this.eventEmitter(GTAG_ACTIONS.finishRegistrationCardTime, {
      finish_registration_card_time: timeSecs,
    });
  }

  public spentTimeRegistrationCard(timeSecs: number) {
    this.currentCardSubject.next('registration_card');
    // console.log('spentTimeRegistrationCard', timeSecs);
    this.eventEmitter(GTAG_ACTIONS.spentTimeRegistrationCard, {
      spentTimeRegistrationCard: timeSecs,
    });
  }

  public startPaymentCard(timeSecs: number) {
    this.currentCardSubject.next('payment_card');
    this.eventEmitter(GTAG_ACTIONS.startPaymentCardTime, {
      start_payment_card_time: timeSecs,
    });
  }

  public finishPaymentCard(timeSecs: number) {
    this.currentCardSubject.next('payment_card');
    this.eventEmitter(GTAG_ACTIONS.finishPaymentCardTime, {
      finish_payment_card_time: timeSecs,
    });
  }

  public spentTimePaymentCard(timeSecs: number) {
    this.currentCardSubject.next('payment_card');
    // console.log('spentTimePaymentCard', timeSecs);
    this.eventEmitter(GTAG_ACTIONS.spentTimePaymentCard, {
      spentTimePaymentCard: timeSecs,
    });
  }

  public frontendValidationError(fieldName: string, message: string) {
    this.eventEmitter(GTAG_ACTIONS.fieldFrontendValidationError, {
      field_name: fieldName,
      message: message,
    });
  }

  public clickUserGuide() {
    this.currentCardSubject.next('getting_started_card');
    this.eventEmitter(GTAG_ACTIONS.clickUserGuide);
  }

  public clickSupportEmail() {
    this.currentCardSubject.next('getting_started_card');
    this.eventEmitter(GTAG_ACTIONS.clickSupportEmail);
  }

  public clickFaq() {
    this.currentCardSubject.next('getting_started_card');
    this.eventEmitter(GTAG_ACTIONS.clickFaq);
  }

  private eventEmitter(eventAction: string, data: any = null) {
    // console.log('eventEmitter: ' + eventAction + ': ' + JSON.stringify(data));
    if (data == null) {
      data = {};
    }

    data.channel_partner = this.channelPartnerSubject.value;
    data.card = this.currentCardSubject.value;

    data.event_callback = function (id) {
      // console.log(
      //   ' -> Sent to GA Tag Manager: ' +
      //     id +
      //     ' -> ' +
      //     eventAction +
      //     ': ' +
      //     JSON.stringify(data)
      // );
      // @TODO Implement your own callback function if needed
    };

    // console.log('gtag: ' + typeof gtag);
    if (typeof gtag === 'function') {
      gtag('event', eventAction, data);
    } else {
      // console.log('gtag undefined: ' + typeof gtag);
    }
  }
}
