import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ta-agency-registration-ofac',
  templateUrl: './agency-registration-ofac.component.html',
})
export class AgencyRegistrationOfacComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
