<div class="container-layout bui-container-layout">

  <div #top id="top" class="container-navbar-top">
    <div class="row">
      <div class="col py-2 text-center">
          <img src="assets/Booking-Logo-Marketing_Booking.svg" alt="Booking.com" class="img-fluid nav-logo nav-logo-lg" />
          <small class="text-nav-logo m-2 text-white">{{'apr.tittlePoweredBy' | translate}}</small>
      </div>
    </div>
  </div>

  <!-- Page content is dynamically displayed using the routing module -->
  <router-outlet></router-outlet>

  <ta-page-spinner></ta-page-spinner>

</div>
