import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AGENCY_ID, AGENCY_ID_TYPE, CLIENT_NAME, COMMUNICATION_EMAIL_CHECK, Constants, EMAIL, NO_IATA_CHECK, OFFICE_ID, PRIVACY_CHECK, TERMS_COND_ADDITIONAL_CHECK, VCC_YN } from '@app/app.constants';
import { AprAgencyInfo } from '@app/models/apr-agency-info.model';
import { AprSave } from '@app/models/apr-save.model';
import { Field } from '@app/models/field.model';
import { PartnerResponse } from '@app/models/partner-response.model';
import { TermsAndConditionsSigned } from '@app/models/terms-and-conditions-signed.model';
import { TermsAndConditions } from '@app/models/terms-and-conditions.model';
import { AprService } from '@app/services/apr.service';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { IpService } from '@core/services/ip.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { editRegistrationFieldsValidator } from '@shared/directives/edit-registration-fields-validator';


@Component({
  selector: 'ta-agency-edit-identity-dynamic',
  templateUrl: './agency-edit-identity-dynamic.component.html',
})
export class AgencyEditIdentityDynamicComponent
implements OnInit, OnChanges, OnDestroy, AfterViewChecked {
  public readonly customerSupportLink = Constants.URL_CUSTOMER_SUPPORT;

  @Output() showEditAlertPage: EventEmitter<any> = new EventEmitter();
  @Output() aprSave: EventEmitter<any> = new EventEmitter();
  @Output() termsAndConditionsSignedEmit: EventEmitter<any> = new EventEmitter();
  @Output() termsAndConditionsAdditionalSignedEmit: EventEmitter<any> = new EventEmitter();
  @Output() partnerIdChange: EventEmitter<any> = new EventEmitter();
  @Output() showCompletePage: EventEmitter<any> = new EventEmitter();
  @Output() agencyCode: EventEmitter<any> = new EventEmitter();

  @Input() registrationForm: FormGroup;
  @Input() partnerIdInfo: PartnerResponse;


  identityForm: FormGroup;
  subscription: Subscription;

  public isValidating = false;
  public isInvalid = false;
  public fieldList: Field[];
  public placeholder: string;
  public isNoIATA = false;
  public agencyTypeCode: string;
  public agencyTypeDisplay: string;
  public aprAgencyInfo: AprAgencyInfo;
  public agencyIdStatus: string;
  public officeIdList: String[];
  public officeIdDuplicatedList: String[];
  public officeIdListToString: string;
  public agreementsPrivacyPolicy: string;
  public agreementsReceiveEmail: string;

  public agreementsTcVcc: string;
  public partnerName: string;
  public termsAndConditionsUrl: string;
  public termsAndConditionsSigned: TermsAndConditionsSigned;
  public termsAndConditionsAdditionalSigned: TermsAndConditionsSigned;

  public vccYnCode: string;
  public vccYnDisplay: string;

  private readonly defaultAgencyTypePlaceholder = 'apr.registrationIdentity.agencyIdTypePlaceholder';
  private readonly defaultAgencyTypeNoIata = 'apr.registrationIdentity.agencyTypeNoIataOption';
  private readonly defaultindustryAgencyIdNotProvided = 'apr.registrationIdentity.industryAgencyIdNotProvided';


  public officeIdConst = OFFICE_ID;
  public agencyIdTypeConst = AGENCY_ID_TYPE;
  public vccYnConst = VCC_YN;
  public agencyIdConst = AGENCY_ID;
  public clientNameConst = CLIENT_NAME;
  public emailConst = EMAIL;
  public noiatacheckConst = NO_IATA_CHECK;
  public privacyCheckConst = PRIVACY_CHECK;
  public communicationEmailCheckConst = COMMUNICATION_EMAIL_CHECK;
  public tcAdditionalCheckConst = TERMS_COND_ADDITIONAL_CHECK;
  public startTime: number;
  public stopTime: number;
  public hasTcAdditional = false;
  public termsAndConditionsAdditionalUrl: string;
  public ipAddress: string;

  public termsAndConditions: TermsAndConditions;
  public termsAndConditionsAdditional: TermsAndConditions;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private aprService: AprService,
    private gaService: GoogleAnalyticsService,
    private ipService: IpService,
    private datePipe: DatePipe,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {


    this.subscription = new Subscription();

    this.subscription.add(
      this.aprService.termsAndConditionsInfo$.subscribe(data => {
        if (data) {
          this.termsAndConditions = data;

          this.termsAndConditionsUrl = this.termsAndConditions.url;
        }
      }));

    this.subscription.add(
      this.aprService.termsAndConditionsAdditionalInfo$.subscribe(data => {
        if (data) {
          this.termsAndConditionsAdditional = data;

          this.termsAndConditionsAdditionalUrl = this.termsAndConditionsAdditional.url;
        }
      }));
    this.getIPAddress();

    this.initForm();

    this.translate.get(this.defaultAgencyTypePlaceholder).subscribe(() => {
      this.agencyTypeDisplay = this.translate.instant(
        this.defaultAgencyTypePlaceholder
      );
    });

  }

  public getIPAddress() {

    this.ipService.getIpAddress
      .subscribe(
        (response: any) => {
          this.ipAddress = JSON.stringify(response.ip).replace(/"/g, '');
        }
      );
  }

  ngOnChanges(changes: SimpleChanges) {
    const change = changes['partnerIdInfo'];
    if (change.firstChange) {
      return;
    }

    if (changes.partnerIdInfo.currentValue !== undefined && this.identityForm) {
      this.partnerIdInfo = changes.partnerIdInfo.currentValue;
      this.fieldList = this.partnerIdInfo.fieldConfig.filter((x) => x.mode && x.mode === 'E');

      this.partnerName = this.partnerIdInfo.partnerName;

      const privacyCheck = this.partnerIdInfo.messageConfig.filter(
        (x) => x.key === PRIVACY_CHECK
      );
      this.agreementsPrivacyPolicy = privacyCheck[0].display;

      const communicationEmailCheck = this.partnerIdInfo.messageConfig.filter(
        (x) => x.key === COMMUNICATION_EMAIL_CHECK
      );
      this.agreementsReceiveEmail = communicationEmailCheck[0].display;

      this.toogleTcAdditional();

      this.generateDynamicForm();

      // Register the Channel Partner login
      this.gaService.channelPartnerLogin(this.partnerName);

    } else if (changes.partnerIdInfo.currentValue !== undefined) {
      this.aprService.setGdsOk(false);
      window.location.reload();
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // refreshLanguage() {
  //   // Dropdown
  //   this.fieldList.filter(
  //     (x) => {
  //       if (this.isDropdownType(x.type)) {
  //         (x.values).map(v => {
  //           if (v.code === Constants.NO_IATA) {
  //             v.display = this.translate.instant(this.defaultAgencyTypeNoIata);
  //           }
  //         });
  //         return (x.values);
  //       }
  //     }
  //   );
  // }

  isVccYnInvalid() {
    if (this.vccYn && this.vccYn.value === null) {
      this.vccYn.setErrors({ required: true });
      return true;
    }
  }

  generateDynamicForm(): void {
    let checkEditRegistrationFieldsValidator = false;

    for (const field of this.fieldList) {
      if (field.validation !== null && field.validation !== undefined) {
        if (field.fieldName === OFFICE_ID) {

          if (field.type !== Constants.READONLY_TYPE) {
            checkEditRegistrationFieldsValidator = true;
          }

          this.identityForm.addControl(
            field.fieldName,
            new FormControl(null, [
              Validators.minLength(field.validation.minSize),
              Validators.maxLength(field.validation.maxSize),
            ])
          );
        } else if (field.fieldName === VCC_YN) {
          this.identityForm.addControl(
            field.fieldName,
            new FormControl(null, [Validators.required])
          );
        } else if (field.fieldName === AGENCY_ID) {

          if (field.type !== Constants.READONLY_TYPE) {
            checkEditRegistrationFieldsValidator = true;
          }

          if (field.errorMsg && field.errorMsg.find(x => x.code === 'required')) {
            this.identityForm.addControl(
              field.fieldName,
              new FormControl(null, [
                Validators.required,
                Validators.minLength(field.validation.minSize),
                Validators.maxLength(field.validation.maxSize),
              ])
            );
          }

          this.identityForm.addControl(
            field.fieldName,
            new FormControl(null, [
              Validators.minLength(field.validation.minSize),
              Validators.maxLength(field.validation.maxSize),
            ])
          );
        }
      } else {
        // without validations
        this.identityForm.addControl(
          field.fieldName,
          new FormControl(null, [])
        );
      }

      // Activation
      this.identityForm.updateValueAndValidity();
    }

    // check if officeId and agencyId are any of them editable
    if (!checkEditRegistrationFieldsValidator) {
      this.identityForm.clearValidators();
    }

    this.registrationForm.addControl('identityForm', this.identityForm);
  }

  initForm(): void {
    this.startTime = performance.now();
    this.gaService.startRegistrationCard(this.startTime);
    this.identityForm = this.fb.group({

    }, {
      validators: [editRegistrationFieldsValidator],
    });
  }

  public removeWhiteSpaces(control: AbstractControl) {
    if (control.value !== null) {
      control.patchValue(control.value.replace(/\s/g, ''));
    }
  }

  isFieldInvalid(field: string) {
    if (this.identityForm && this.identityForm.get(field) !== null) {
      return (
        this.identityForm.get(field).touched &&
        this.hasErrors(this.identityForm.get(field))
      );
    }
  }

  hasErrorRequiredFields() {

    if (this.officeId && this.agencyId) {
      if (this.identityForm.hasError('requiredFields')) {
        this.officeId.setErrors({ required: true });
        this.agencyId.setErrors({ required: true });
      } else {
        this.officeId.setErrors({ required: false });
        this.agencyId.setErrors({ required: false });

        this.officeId.updateValueAndValidity();
        this.agencyId.updateValueAndValidity();
      }
    }

    return this.identityForm.hasError('requiredFields');
  }

  hasErrors(field: AbstractControl) {
    let hasError = false;
    const controlErrors: ValidationErrors = field.errors;
    if (controlErrors != null) {
      Object.keys(controlErrors).forEach(keyError => {
        if (controlErrors[keyError]) {
          hasError = true;
        }
      });

    }
    return hasError;
  }

  isFormValid() {
    return !this.identityForm.valid && this.identityForm.touched;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: false });
        this.isInvalid = true;
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onChangeVcc(): void {
    if (this.vccYn) {
      document.getElementById('vccYnInput').style.fontStyle =
        Constants.NORMAL_FONT;
      this.vccYn.patchValue(this.vccYn.value);
      this.vccYnCode = this.vccYn.value.code;
      this.vccYnDisplay = this.vccYn.value.display;
    }

    this.subscription.add(
      this.aprService.getPartnerByNameAndVcc(this.partnerIdInfo.partnerName, this.vccYnCode).subscribe(response => {
        this.partnerIdInfo.partnerId = response.body.partnerId;
        this.partnerIdChange.emit(this.partnerIdInfo.partnerId);
      }));

    this.toogleTcAdditional();
  }

  isInputType(type: string): boolean {
    return type.indexOf(Constants.INPUT_TYPE) !== -1;
  }

  isCheckboxType(type: string): boolean {
    return type.indexOf(Constants.CHECKBOX_TYPE) !== -1;
  }

  isDropdownType(type: string): boolean {
    return type.indexOf(Constants.DROPDOWN_TYPE) !== -1;
  }

  isReadOnlyType(type: string): boolean {
    return type.indexOf(Constants.READONLY_TYPE) !== -1;
  }

  hasLabel(item: Field): boolean {
    return (
      item.fieldName.indexOf(OFFICE_ID) !== -1 ||
      item.fieldName.indexOf(AGENCY_ID) !== -1 ||
      item.fieldName.indexOf(VCC_YN) !== -1
    );
  }

  changeFontStyle(typeCode: string): string {
    if (typeCode.indexOf(Constants.NO_IATA) !== -1) {
      return Constants.ITALIC_FONT;
    } else {
      return Constants.NORMAL_FONT;
    }
  }

  getError(item: Field, code: string): string {
    const field = item.errorMsg.filter((x) => x.code === code);
    return field[0] ? field[0].display : null;
  }

  setToUpperCase(control: AbstractControl): void {
    if (control) {
      control.setValue(control.value.toUpperCase());
    }
  }

  onContinueClicked(): void {
    if (this.identityForm.valid) {
      // check validations
      this.isValidating = true;
      this.identityForm.markAsUntouched();
      this.identityForm.disable();

      // if (this.officeId && this.officeId.value === null) {
      //   this.officeId.patchValue(this.partnerIdInfo.details.uniqueId);
      // }

      // Generate input for activate service
      this.aprAgencyInfo = {
        agencyCode: this.agencyId ? this.agencyId.value : null,
        agencyIdType: null,
        email: null,
        partnerId: this.partnerIdInfo.partnerId,
        otaId: this.partnerIdInfo.otaId,
        officeId: this.officeId ? this.officeId.value : '',
      };

      // readonly fields
      if (this.partnerIdInfo && this.partnerIdInfo.details) {
        this.aprAgencyInfo.officeId = this.partnerIdInfo.details.uniqueId;
        this.aprAgencyInfo.agencyCode = this.partnerIdInfo.details.onyxTaCode;
      }

      const save = this.transformModel(this.aprAgencyInfo);

      this.aprService
        .upsertRegistration(save)
        .subscribe((response) => {
          this.agencyCode.emit(this.aprAgencyInfo.agencyCode);

          if (response) {
            let messageCode: string = null;
            let alertComplete = false;

            switch (response) {
              case Constants.PARTNERID_NOT_FOUND: {
                messageCode = 'apr.registrationUpdateIdentity.partnerIdNotFound';
                break;
              }
              case Constants.OFFICEID_ALREADY_VCC_SIGNED: {
                messageCode = `apr.registrationUpdateIdentity.${this.partnerName.toLowerCase()}.officeIdVccAlreadySigned`;
                break;
              }
              case Constants.OFFICEID_REGISTRATION_NOT_FOUND: {
                messageCode = `apr.registrationUpdateIdentity.${this.partnerName.toLowerCase()}.officeIdRegistrationNotFound`;
                break;
              }
              case Constants.AGENCYCODE_ALREADY_VCC_SIGNED: {
                messageCode = 'apr.registrationUpdateIdentity.agencyCodeVccAlreadySigned';
                break;
              }
              case Constants.AGENCYCODE_REGISTRATION_NOT_FOUND: {
                messageCode = 'apr.registrationUpdateIdentity.agencyCodeRegistrationNotFound';
                break;
              }
              case Constants.SWITCH_TO_VCC_N_NOT_ALLOW: {
                messageCode = 'apr.registrationUpdateIdentity.switchToVccNNotAllow';
                break;
              }
              default: {
                messageCode = 'apr.registrationUpdateIdentity.completePage';
                alertComplete = true;
                break;
              }
            }

            this.aprService.setEditAlertCode(messageCode, alertComplete);
            this.showEditAlertPage.emit(true);
          }

        });

      this.stopTime = performance.now();
      this.gaService.finishRegistrationCard(this.stopTime);
      this.gaService.spentTimeRegistrationCard(this.stopTime - this.startTime);
    } else {
      this.validateAllFormFields(this.identityForm);
    }
  }

  transformModel(response: AprAgencyInfo): AprSave {

    this.setTermsAndcontionsSigned();

    const save: AprSave = {
      registrationId: null,
      partnerId: this.partnerIdInfo.partnerId,
      otaId: this.partnerIdInfo.otaId,
      status: response.isOfacSanctioned ? Constants.STEP_OFACCONUNTRY : Constants.STEP_REGISTRATION,
      registrationDetails: {
        sourceId: this.partnerIdInfo.partnerId,
        officeId: response.officeId,
        agencyType: null,
        agencyCode: response.agencyCode,
        email: null,
        termsAndConditions: null,
        marketingNewsletter: null,
        noIataCheck: null,
        tcAdditional: this.tcAdditionalCheck ? this.tcAdditionalCheck.value : false,
        termsAndConditionsSigned: {
          id: null,
          ipAddress: null,
          timestamp: null,
        },
        termsAndConditionsAdditionalSigned: {
          id: null,
          ipAddress: null,
          timestamp: null,
        },
      },
      profileDetails: {},
      lang: this.translate.currentLang,
      isMultiAid: this.partnerIdInfo.isMultiAid ? this.partnerIdInfo.isMultiAid : false,
      hasTcAdditional: this.partnerIdInfo.hasTcAdditional ? this.partnerIdInfo.hasTcAdditional : false,
    };

    // readonly fields
    if (this.partnerIdInfo && this.partnerIdInfo.details) {
      save.registrationDetails.officeId = this.partnerIdInfo.details.uniqueId;
      save.registrationDetails.agencyCode = response.agencyCode;
      save.profileDetails.companyName = response.companyName;
      save.registrationDetails.agencyType = response.agencyIdType;
      save.registrationDetails.noIataCheck = true;
    }

    if (this.termsAndConditionsSigned) {
      save.registrationDetails.termsAndConditionsSigned.id = this.termsAndConditionsSigned.id;
      save.registrationDetails.termsAndConditionsSigned.ipAddress = this.termsAndConditionsSigned.ipAddress;
      save.registrationDetails.termsAndConditionsSigned.timestamp = this.termsAndConditionsSigned.timestamp;
    }

    if (this.termsAndConditionsAdditionalSigned) {
      save.registrationDetails.termsAndConditionsAdditionalSigned.id = this.termsAndConditionsAdditionalSigned.id;
      save.registrationDetails.termsAndConditionsAdditionalSigned.ipAddress = this.termsAndConditionsAdditionalSigned.ipAddress;
      save.registrationDetails.termsAndConditionsAdditionalSigned.timestamp = this.termsAndConditionsAdditionalSigned.timestamp;
    }

    this.aprSave.emit(save);

    return save;
  }

  public setTermsAndcontionsSigned() {
    // default terms and conditions
    if (this.termsAndConditions) {
      this.termsAndConditionsSigned = {
        id: this.termsAndConditions.id,
        ipAddress: this.ipAddress,
        timestamp: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      };
      this.termsAndConditionsSignedEmit.emit(this.termsAndConditionsSigned);
    }
    // additional terms and conditions
    if (this.termsAndConditionsAdditional) {
      this.termsAndConditionsAdditionalSigned = {
        id: this.termsAndConditionsAdditional.id,
        ipAddress: this.ipAddress,
        timestamp: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      };
      this.termsAndConditionsAdditionalSignedEmit.emit(this.termsAndConditionsAdditionalSigned);
    }



  }

  public sendGAInfo(field: any, key: string) {
    if (this.identityForm.get(field).errors) {
      this.gaService.frontendValidationError(field, key);
    }
  }

  private toogleTcAdditional() {
    let tcAdditionalCheck = null;
    if (this.partnerIdInfo && this.partnerIdInfo.messageConfig) {
      tcAdditionalCheck = this.partnerIdInfo.messageConfig.filter(
        (x) => x.key === TERMS_COND_ADDITIONAL_CHECK
      );
    }


    if (this.partnerIdInfo.hasTcAdditional && this.vccYn && this.vccYn.value && this.vccYn.value.code === 'Y') {
      this.identityForm.addControl('tcAdditionalCheck', new FormControl(false, [Validators.requiredTrue]));
      this.hasTcAdditional = true;
    } else if (this.partnerIdInfo.hasTcAdditional && this.partnerIdInfo.vccYn === 'Y' && !this.vccYn) {
      this.identityForm.addControl('tcAdditionalCheck', new FormControl(false, [Validators.requiredTrue]));
      this.hasTcAdditional = true;
    } else {
      this.identityForm.removeControl('tcAdditionalCheck');
      this.hasTcAdditional = false;
    }
  }

  get officeId() {
    return this.identityForm.get(OFFICE_ID);
  }
  get agencyIdType() {
    return this.identityForm.get(AGENCY_ID_TYPE);
  }
  get agencyId() {
    return this.identityForm.get(AGENCY_ID);
  }
  get clientName() {
    return this.identityForm.get(CLIENT_NAME);
  }
  get email() {
    return this.identityForm.get(EMAIL);
  }
  get noIataCheck() {
    return this.identityForm.get(NO_IATA_CHECK);
  }
  get privacyCheck() {
    return this.identityForm.get(PRIVACY_CHECK);
  }
  get communicationEmailCheck() {
    return this.identityForm.get(COMMUNICATION_EMAIL_CHECK);
  }
  get tcAdditionalCheck() {
    return this.identityForm.get(TERMS_COND_ADDITIONAL_CHECK);
  }
  get vccYn() {
    return this.identityForm.get(VCC_YN);
  }

}
