import { FormGroup } from '@angular/forms';

export function bankNameValidator(fg: FormGroup) {

  const bankName = fg.value;
  const alfaNumericPattern = '(.*[A-z0-9]){3}';
  let isValid = false;

  if (bankName) {
    const alfaNumericRegexp = new RegExp(alfaNumericPattern);

    isValid = alfaNumericRegexp.test(bankName);

    return isValid ? null : { bankNameInvalid: true };
  }

}

