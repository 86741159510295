<div class="container-layout">
    <div class="container-fluid container-content">
      <div class="marquee">
        <i class="far fa-file-exclamation marquee-icon"></i>
        <h1 class="marquee-heading">{{'apr.ofacPage.header' | translate}}</h1>
        <h2 class="marquee-message">
          <p>
            {{'apr.ofacPage.bodyText1' | translate}}
          </p>
        </h2>
        <p>
          {{'apr.ofacPage.closeWindow' | translate}}
        </p>
      </div>
    </div>
  </div>
  
  