<div class="card">
  <div class="card-header">
    <h2 class="card-title">{{'apr.registrationUpdateIdentity.header' | translate}}</h2>
  </div>
  <div class="card-body">
    <form [formGroup]="identityForm">
      <div *ngFor="let item of fieldList">
        <div class="form-group row"
          [ngClass]="{ 'form-group-invalid': isFieldInvalid(item.fieldName) }">
          <!-- labels -->
          <label *ngIf="hasLabel(item) && !isReadOnlyType(item.type) && item.fieldName != 'vccYn'"
            class="col-form-label col-lg-3">
            {{item.keyi18n | translate}}
          </label>

          <label *ngIf="hasLabel(item) && !isReadOnlyType(item.type) && item.fieldName == 'vccYn'" 
            class="col-form-label col-lg-3"
            tooltip="{{'apr.registrationIdentity.vccTooltip' | translate}}" 
            placement="left">
            {{item.keyi18n | translate}}
          </label>


          <!-- controls -->
          <div *ngIf="isDropdownType(item.type) && item.fieldName == 'vccYn'" class="col-lg-9">
            <select id=vccYnInput class="form-control" formControlName={{item.fieldName}} name={{item.fieldName}}
              (change)="onChangeVcc()" [ngClass]="{ 'is-invalid': (isVccYnInvalid() && vccYn.touched) }">
              <option [ngValue]="null" disabled>{{item.placeholder | translate}}</option>
              <option *ngFor="let type of item.values" [ngValue]="type" [style.font-style]="changeFontStyle(type.code)">
                {{type.display | translate}}</option>
            </select>
          </div>


          <div *ngIf="isInputType(item.type) && !item.readonly" class="col-lg-9">
            <div *ngIf="(item.fieldName).indexOf(agencyIdConst) === -1 && (item.fieldName).indexOf(officeIdConst) === -1">
              <input type="text" taNoWhitespace class="form-control"
                placeholder="{{item.placeholder | translate}}"
                formControlName="{{item.fieldName}}" name="{{item.fieldName}}"
                [ngClass]="{ 'is-invalid': isFieldInvalid(item.fieldName)}"
                (keyup)="(item.fieldName.indexOf(officeIdConst) !== -1) && setToUpperCase(officeId)"
                (blur)="((item.fieldName.indexOf(officeIdConst) !== -1) && removeWhiteSpaces(officeId)) || sendGAInfo(item.fieldName,item.fieldName+'Invalid')" />
            </div>
            <div *ngIf="(item.fieldName).indexOf(officeIdConst) !== -1">
              <input id="agency-id-input" type="text" class="form-control"
                placeholder="{{item.placeholder | translate }}"
                formControlName={{item.fieldName}} name={{item.fieldName}}
                [ngClass]="{ 'is-invalid': isFieldInvalid(officeIdConst) }"
                (keyup)="setToUpperCase(officeId)"
                (blur)="removeWhiteSpaces(officeId) || sendGAInfo(item.fieldName,item.fieldName+'Invalid')" />
                <small *ngIf="item.fieldName.indexOf(officeIdConst) !== -1" class="form-text text-muted">{{item.formatMsg
                  | translate}}</small>
            </div>
            <div *ngIf="(item.fieldName).indexOf(agencyIdConst) !== -1">
              <input id="agency-id-input" type="text" class="form-control"
                placeholder="{{item.placeholder | translate }}"
                formControlName={{item.fieldName}} name={{item.fieldName}}
                [ngClass]="{ 'is-invalid': isFieldInvalid(agencyIdConst) }"
                (blur)="sendGAInfo(item.fieldName,item.fieldName+'Invalid')" />
            </div>
          </div>

          <div *ngIf="isReadOnlyType(item.type)" class="form-control-plaintext">
            <label class="col-form-label col-lg-3">{{item.keyi18n | translate}}</label>
            <span class="col-lg-1"
              *ngIf="item.fieldName.indexOf(officeIdConst) !== -1">{{partnerIdInfo.details.uniqueId}}</span>
            <span class="col-lg-1"
              *ngIf="item.fieldName.indexOf(clientNameConst) !== -1">{{partnerIdInfo.details.clientName}}</span>
          </div>

          <!-- Error msg management -->
          <span *ngIf="identityForm.controls[item.fieldName] && item.fieldName.indexOf(officeIdConst) !== -1" class="offset-lg-3 col-lg-9">
            <span class="invalid-feedback" *ngIf="hasErrorRequiredFields() && isFieldInvalid(officeIdConst)">
              <span *ngIf="item.errorMsg">{{getError(item, 'requiredFields') | translate}}</span>
            </span>
            
            <span class="invalid-feedback" *ngIf="(identityForm.controls[item.fieldName].hasError('minlength') || identityForm.controls[item.fieldName].hasError('maxlength'))">
              <span *ngIf="item.errorMsg">{{getError(item, 'formatOfficeIdInvalid') | translate}}</span>
            </span>
          </span>

          <span *ngIf="identityForm.controls[item.fieldName] && item.fieldName.indexOf(agencyIdConst) !== -1" class="offset-lg-3 col-lg-9">
            <span class="invalid-feedback" *ngIf="hasErrorRequiredFields() && isFieldInvalid(agencyIdConst)">
              <span *ngIf="item.errorMsg">{{getError(item, 'requiredFields') | translate}}</span>
            </span>
            
            <span class="invalid-feedback" *ngIf="((identityForm.controls[item.fieldName].hasError('minlength')) || (identityForm.controls[item.fieldName].hasError('maxlength')))">
              <span *ngIf="item.errorMsg">{{getError(item, 'formatAgencyInvalid') | translate}}</span>
            </span>
          </span>

        </div>
      </div> <!-- loop -->

      <!-- Agreements section -->
      <div class="form-group row mb-0"
        [ngClass]="{ 'form-group-invalid': (isFieldInvalid(privacyCheckConst) || isFieldInvalid(tcAdditionalCheckConst)) }">
        <label for="agreement-text" class="col-lg-3 col-form-label">{{'apr.registrationIdentity.agreementsLabel' |
          translate}}</label>
        <div class="col-lg-9">
          <p class="form-control-plaintext"
            [innerHTML]="'apr.registrationIdentity.agreementsLink' | translate | replace: {url: termsAndConditionsUrl}">
          </p>

          <div id="agreement-text">
            <!-- <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="privacyCheck" formControlName="privacyCheck"
                    (blur)="sendGAInfo('privacyCheck','privacyCheckInvalid')">
                    <label class="custom-control-label" for="privacyCheck">{{agreementsPrivacyPolicy | translate | replace: {partnerName: partnerName} }}</label>
                  </div>
                  <div class="form-group custom-control custom-checkbox form-group-invalid:false">
                    <input type="checkbox" class="custom-control-input" id="communicationEmailCheck" formControlName="communicationEmailCheck"
                    (blur)="sendGAInfo('communicationEmailCheck','communicationEmailCheckInvalid')">
                    <label class="custom-control-label" for="communicationEmailCheck">{{agreementsReceiveEmail | translate}}</label>
                  </div> -->
            <div class="custom-control custom-checkbox" *ngIf="this.hasTcAdditional">
              <input type="checkbox" class="custom-control-input" id="tcAdditionalCheck"
                formControlName="tcAdditionalCheck" (blur)="sendGAInfo('tcAdditionalCheck','tcAdditionalCheckInvalid')">
              <label class="custom-control-label"
                for="tcAdditionalCheck">{{'apr.registrationIdentity.agreementsTcAdditional_1' | translate}}<a
                  href={{termsAndConditionsAdditionalUrl}}
                  target=_blank>{{'apr.registrationIdentity.agreementsTcAdditional_2' | translate}}<i
                    class='far fa-external-link'></i></a> {{'apr.registrationIdentity.agreementsTcAdditional_3' |
                translate
                | replace: {partnerName: partnerName} }} </label>
            </div>
          </div>
          <div class="invalid-feedback">
            <span>{{'apr.registrationIdentity.agreementsErrorText' | translate}}</span>
          </div>
        </div>
      </div>

    </form>
  </div> <!-- card body -->

  <!-- Button Continue -->
  <div class="card-footer">
    <div class="row">
      <div class="offset-lg-8 col-lg-4">
        <button *ngIf="isValidating" type="button" class="btn btn-primary btn-block" disabled>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{'apr.registrationIdentity.verifyingSubmitBtnLabel' | translate}}
        </button>
        <button *ngIf="!isValidating" type="submit" class="btn btn-primary btn-block"
          (click)="onContinueClicked($event)">
          {{'apr.registrationIdentity.continueSubmitBtnLabel' | translate}}</button>
        <div *ngIf="isFormValid()" class="form-group form-group-invalid mb-0">
          <div class="invalid-feedback">
            <span>{{'apr.registrationIdentity.submitErrorText' | translate}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> <!-- card -->