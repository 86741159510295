import { FormGroup } from '@angular/forms';

export function emailLenghtFormat(control: FormGroup) {
  const email = control.value;

  if (!email) {
    return null;
  }

  if ( email.length > 70) {
    return { maxlengthInvalid: true };
  }

  const EMAIL_REGEXP = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  if (email !== '' && (!EMAIL_REGEXP.test(email))) {
    return { invalid: true };
  }

  return null;
}
