import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IpService {

  constructor(private http: HttpClient) { }

  public getIpAddress = new Observable<string>(observer => {
    fetch('https://api.ipify.org/?format=json', { mode: 'cors' })
      .then((resp) => resp.json())
      .then((ip) => {
        if (ip === '') {
          observer.next('IP not found');
        }
        observer.next(ip);
      });
  });

}
