import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Constants } from '@app/app.constants';
import { AprSave } from '@app/models/apr-save.model';
import { FieldRules } from '@app/models/field-rules.model';
import { AprService } from '@app/services/apr.service';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { CommonDataService } from '@core/services';
import { bankNameValidator } from '@shared/directives/bank-name-validator';
import { IbanValidator } from '@shared/directives/iban-validator';
import { OfacRoutingValidator } from '@shared/directives/ofac-routing-validator';
import { latinPatternValidator } from '@shared/directives/latin-pattern-validator';
import { Dropdown } from '@shared/models';
import { Observable } from 'rxjs';
import { OfacSwiftValidator } from '../../shared/directives/ofac-swift-validator';
import { LogServiceClientService, LogLevel } from '@onyx/log-service-client-11';
import { TermsAndConditionsSigned } from '@app/models/terms-and-conditions-signed.model';

@Component({
  selector: 'ta-agency-registration-payment',
  templateUrl: './agency-registration-payment.component.html',
})
export class AgencyRegistrationPaymentComponent implements OnInit, AfterViewInit {

  customerSupportUrl: string = Constants.URL_CUSTOMER_SUPPORT;

  private readonly defaultIbanCountryError = 'apr.registrationPayment.ibanCountryError';
  private readonly defaultIbanMinLengthError = 'apr.registrationPayment.ibanMinLengthError';

  @Input() registrationForm: FormGroup;
  @Input() partnerId: string;
  @Input() partnerName: string;
  @Input() showFormPayment: boolean;
  @Input() aprSave: AprSave;
  @Input() registrationId: number;
  @Input() termsAndConditionsSigned: TermsAndConditionsSigned;
  @Input() termsAndConditionsAdditionalSigned: TermsAndConditionsSigned;


  @Output() showCompletePage: EventEmitter<any> = new EventEmitter();
  @Output() toStep3Emit: EventEmitter<any> = new EventEmitter();

  hasPaymentDetails: boolean;
  isValidating = false;
  isInvalid = false;
  isComplete = false;
  hasBankStates = false;
  paymentForm: FormGroup;
  stateName: string;

  bankCountries$ = this.aprService.countries$;
  bankStates$: Observable<Dropdown[]>;
  currencies$ = this.aprService.currencies$;
  fieldRulesList: FieldRules[];

  accountTypesList = Constants.APR_ACCOUNT_TYPES;

  public startTime: number;
  public stopTime: number;

  temporal: string;

  public ibanCountryErrorDisplay: string;
  public ibanMinLengthErrorDisplay: string;

  previousOfacCode: string | undefined;

  constructor(
    private fb: FormBuilder,
    private commonDataService: CommonDataService,
    private aprService: AprService,
    private translateService: TranslateService,
    private gaService: GoogleAnalyticsService,
    private logService: LogServiceClientService
  ) { }


  ngOnInit() {

    if (this.showFormPayment) {
      this.hasPaymentDetails = true;
      this.initForm();
    } else {
      this.hasPaymentDetails = false;
      this.initForm();

      this.paymentForm.removeControl('selectedPaymentMethodCurrency');
      this.paymentForm.removeControl('bankName');
      this.paymentForm.removeControl('bankSelectedCountry');
      this.paymentForm.removeControl('bankAddress');
      this.paymentForm.removeControl('bankCity');
      this.paymentForm.removeControl('bankZipCode');

    }

    this.translateService.get(this.defaultIbanCountryError).subscribe(() => {
      this.ibanCountryErrorDisplay = this.translateService.instant(
        this.defaultIbanCountryError
      );
    });

    this.translateService.get(this.defaultIbanMinLengthError).subscribe(() => {
      this.ibanMinLengthErrorDisplay = this.translateService.instant(
        this.defaultIbanMinLengthError
      );
    });

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.paymentMethod.patchValue(this.translateService.instant('apr.registrationPayment.directDepositPlaceHolder'));
      this.translateService.use(event.lang);
      localStorage.setItem(Constants.LANG_KEY, event.lang);
      this.refreshLanguage();
    });

  }

  public ngAfterViewInit(): void {
    this.toStep3Emit.emit(this.hasPaymentDetails);
  }

  initForm(): void {
    this.startTime = performance.now();
    this.gaService.startPaymentCard(this.startTime);
    this.paymentForm = this.fb.group({
      paymentMethod: [this.translateService.instant('apr.registrationPayment.directDepositPlaceHolder')],
      selectedPaymentMethodCurrency: [null, [Validators.required]],
      bankName: ['', [Validators.required, Validators.maxLength(36), latinPatternValidator, bankNameValidator]],
      bankSelectedCountry: [null, [Validators.required]],
      bankAddress: ['', [Validators.required, Validators.maxLength(50), latinPatternValidator]],
      bankCity: ['', [Validators.required, latinPatternValidator]],
      bankZipCode: ['', [Validators.maxLength(10), latinPatternValidator]],
    });

    this.registrationForm.addControl('paymentForm', this.paymentForm);

  }

  isFieldValid(field: string) {
    if (this.paymentForm.get(field) != null) {
      return this.paymentForm.get(field).touched && this.paymentForm.get(field).errors;
    }
  }

  isFormValid() {
    return !this.paymentForm.valid && this.paymentForm.touched;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: false });
        this.isInvalid = true;
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  hasCountrySelection() {
    return this.selectedCountry.value !== null;
  }

  onBankCountryChange(stateId?: string) {
    if (this.commonDataService.hasCountryStates(this.selectedCountry.value.code)) {
      this.paymentForm.addControl('bankSelectedState', new FormControl(null, [Validators.required]));
      this.bankStates$ = this.commonDataService.loadStatesByCountryAPR(this.selectedCountry.value.code);
      this.commonDataService.states$.subscribe(() => {
        this.bankSelectedState.patchValue(this.commonDataService.getStateById(stateId));
      });

      this.hasBankStates = true;

    } else {
      this.hasBankStates = false;
      if (this.paymentForm.get('bankSelectedState')) {
        this.paymentForm.removeControl('bankSelectedState');
      }
    }

    // Bank Rules
    this.resetControls();
    this.generateDynamicFormByCountry(this.selectedCountry.value.code);
  }

  generateDynamicFormByCountry(countryCode: string): void {

    this.commonDataService.getBankRules(countryCode).subscribe(response => {
      if (!response !== undefined) {
        this.fieldRulesList = response.listFieldRules;
        for (const fieldRule of response.listFieldRules) {

          if (fieldRule.pattern !== null) {
            if (fieldRule.fieldName === 'routingCode') {
              this.paymentForm.addControl(fieldRule.fieldName, new FormControl(null, [
                Validators.required, Validators.pattern(fieldRule.pattern)],
              OfacRoutingValidator.createValidator(this.aprService)
              ));
              this.paymentForm.updateValueAndValidity();
            } else if (fieldRule.fieldName === 'swift') {
              this.paymentForm.addControl(fieldRule.fieldName, new FormControl(null, [
                Validators.required,
                Validators.pattern(fieldRule.pattern)],
              OfacSwiftValidator.createValidator(this.aprService)
              ));
              this.paymentForm.updateValueAndValidity();
            } else {
              this.paymentForm.addControl(fieldRule.fieldName,
                new FormControl(null, [Validators.required, Validators.pattern(fieldRule.pattern), latinPatternValidator]));
            }
          } else {
            if (fieldRule.minSize !== null && fieldRule.maxSize !== null) {
              this.paymentForm.addControl(fieldRule.fieldName, new FormControl(null, [Validators.required,
                Validators.minLength(fieldRule.minSize), Validators.maxLength(fieldRule.maxSize), latinPatternValidator]));
            } else if (fieldRule.minSize === null && fieldRule.maxSize === null) {
              if (fieldRule.fieldName === 'iban') {
                this.paymentForm.addControl(fieldRule.fieldName, new FormControl(null, [Validators.required],
                  IbanValidator.createValidator(this.aprService,
                    this.translateService.currentLang, this.selectedCountry.value.code)));

                this.paymentForm.updateValueAndValidity();
              } else {
                this.paymentForm.addControl(fieldRule.fieldName, new FormControl(null, [Validators.required, latinPatternValidator]));
              }
            } else if (fieldRule.minSize !== null) {
              this.paymentForm.addControl(fieldRule.fieldName, new FormControl(null, [Validators.required,
                Validators.minLength(fieldRule.minSize), latinPatternValidator]));
            } else if (fieldRule.maxSize !== null) {
              this.paymentForm.addControl(fieldRule.fieldName, new FormControl(null, [Validators.required,
                Validators.maxLength(fieldRule.maxSize), latinPatternValidator]));
            }
          }

        }
      }
    });
  }

  compareFn(d1: Dropdown, d2: Dropdown): boolean {
    return d1 && d2 ? d1.code === d2.code && d1.name === d2.name : d1 === d2;
  }

  resetControls(): void {
    if (this.accountHolder) {
      this.paymentForm.removeControl('accountHolder');
    }
    if (this.accountNumber) {
      this.paymentForm.removeControl('accountNumber');
    }
    if (this.accountSuffix) {
      this.paymentForm.removeControl('accountSuffix');
    }
    if (this.accountType) {
      this.paymentForm.removeControl('accountType');
    }
    if (this.achRouting) {
      this.paymentForm.removeControl('achRouting');
    }
    if (this.bankCode) {
      this.paymentForm.removeControl('bankCode');
    }
    if (this.bankTransitNumber) {
      this.paymentForm.removeControl('bankTransitNumber');
    }
    if (this.branchCode) {
      this.paymentForm.removeControl('branchCode');
    }
    if (this.branchNumber) {
      this.paymentForm.removeControl('branchNumber');
    }
    if (this.bsbCode) {
      this.paymentForm.removeControl('bsbCode');
    }
    if (this.clabe) {
      this.paymentForm.removeControl('clabe');
    }
    if (this.iban) {
      this.paymentForm.removeControl('iban');
    }
    if (this.routingCode) {
      this.paymentForm.removeControl('routingCode');
    }
    if (this.swift) {
      this.paymentForm.removeControl('swift');
    }
  }

  onContinueClicked() {

    if (this.paymentForm.valid) {
      // check validations
      this.isValidating = true;
      this.isComplete = true;
      this.paymentForm.markAsUntouched();
      this.paymentForm.disable();

      this.aprService.saveStep(this.transformModel()).subscribe();

      // show confirmation page
      this.stopTime = performance.now();
      this.gaService.finishPaymentCard(this.stopTime);
      this.gaService.spentTimePaymentCard(this.stopTime - this.startTime);

      this.showCompletePage.emit(true);

    } else {
      this.validateAllFormFields(this.paymentForm);
    }

  }

  transformModel(): AprSave {
    let save: AprSave = null;

    if (this.showFormPayment) {
      save = {
        registrationId: this.aprSave.registrationId,
        partnerId: this.aprSave.partnerId,
        otaId: this.aprSave.otaId,
        status: `${Constants.STEP_PAYMENTS},${Constants.STEP_USERCOMPLETE}`,
        registrationDetails: {
          sourceId: this.aprSave.partnerId,
          officeId: this.aprSave.registrationDetails.officeId,
          agencyType: this.aprSave.registrationDetails.agencyType,
          agencyCode: this.aprSave.registrationDetails.agencyCode,
          email: this.aprSave.registrationDetails.email,
          termsAndConditions: this.aprSave.registrationDetails.termsAndConditions,
          marketingNewsletter: this.aprSave.registrationDetails.marketingNewsletter,
          noIataCheck: this.aprSave.registrationDetails.noIataCheck,
          termsAndConditionsSigned: {
            id: null,
            ipAddress: null,
            timestamp: null,
          },
          termsAndConditionsAdditionalSigned: {
            id: null,
            ipAddress: null,
            timestamp: null,
          },
        },
        profileDetails: {
          companyName: this.aprSave.profileDetails.companyName,
          contactName: this.aprSave.profileDetails.contactName,
          country: this.aprSave.profileDetails.country,
          address: this.aprSave.profileDetails.address,
          city: this.aprSave.profileDetails.city,
          state: this.aprSave.profileDetails.state,
          postalCode: this.aprSave.profileDetails.postalCode,
          member: this.aprSave.profileDetails.member,
          askPaymentDetails: this.aprSave.profileDetails.askPaymentDetails,
          askProfileDetails: this.aprSave.profileDetails.askProfileDetails,
          existsMainContact: this.aprSave.profileDetails.existsMainContact,
          existsPortalUser: this.aprSave.profileDetails.existsPortalUser,
          isPortalUser: this.aprSave.profileDetails.isPortalUser,
          isMainContact: this.aprSave.profileDetails.isMainContact,
          payLoc: this.aprSave.profileDetails.payLoc,
          isPortalUserOfAnyEntity: this.aprSave.profileDetails.isPortalUserOfAnyEntity,
          isOfacSanctioned: this.aprSave.profileDetails.isOfacSanctioned,
        },
        paymentDetails: {
          paymentMethod: this.paymentMethod ? this.paymentMethod.value : null,
          currency: this.selectedPaymentMethodCurrency && (this.selectedPaymentMethodCurrency.value) ?
            this.selectedPaymentMethodCurrency.value.code : null,
          bankName: this.bankName ? this.bankName.value : null,
          bankCountry: this.selectedCountry && (this.selectedCountry.value) ? this.selectedCountry.value.code : null,
          bankAddress: this.address ? this.address.value : null,
          bankAccountName: null,
          bankCity: this.city ? this.city.value : null,
          bankState: this.hasBankStates && (this.bankSelectedState.value) ? this.bankSelectedState.value.code : null,
          bankPostal: this.zipCode ? this.zipCode.value : null,
          accountHolderName: this.accountHolder ? this.accountHolder.value : null,
          bankCode: this.bankCode ? this.bankCode.value : null,
          bankBranchCode: this.branchCode ? this.branchCode.value : null,
          accountNumber: this.accountNumber ? this.accountNumber.value : null,
          swift: this.swift ? this.swift.value : null,
          iban: this.iban ? this.iban.value : null,
          accountSuffix: this.accountSuffix ? this.accountSuffix.value : null,
          accountType: this.accountType && (this.accountType.value) ? this.accountType.value.code : null,
          bankTransitNumber: this.bankTransitNumber ? this.bankTransitNumber.value : null,
          achRouting: this.achRouting ? this.achRouting.value : null,
          branchCode: this.branchCode ? this.branchCode.value : null,
          branchNumber: this.branchNumber ? this.branchNumber.value : null,
          bsbCode: this.bsbCode ? this.bsbCode.value : null,
          clabe: this.clabe ? this.clabe.value : null,
          routingCode: this.routingCode ? this.routingCode.value : null,
        },
        lang: this.translateService.currentLang,
        isMultiAid: this.aprSave.isMultiAid ? this.aprSave.isMultiAid : false,
      };
    } else {
      save = {
        registrationId: this.aprSave.registrationId,
        partnerId: this.aprSave.partnerId,
        otaId: this.aprSave.otaId,
        status: `${Constants.STEP_PAYMENTS},${Constants.STEP_USERCOMPLETE}`,
        registrationDetails: {
          sourceId: this.aprSave.partnerId,
          officeId: this.aprSave.registrationDetails.officeId,
          agencyType: this.aprSave.registrationDetails.agencyType,
          agencyCode: this.aprSave.registrationDetails.agencyCode,
          email: this.aprSave.registrationDetails.email,
          termsAndConditions: this.aprSave.registrationDetails.termsAndConditions,
          marketingNewsletter: this.aprSave.registrationDetails.marketingNewsletter,
          noIataCheck: this.aprSave.registrationDetails.noIataCheck,
          termsAndConditionsSigned: {
            id: null,
            ipAddress: null,
            timestamp: null,
          },
          termsAndConditionsAdditionalSigned: {
            id: null,
            ipAddress: null,
            timestamp: null,
          },
        },
        profileDetails: {
          companyName: this.aprSave.profileDetails.companyName,
          contactName: this.aprSave.profileDetails.contactName,
          country: this.aprSave.profileDetails.country,
          address: this.aprSave.profileDetails.address,
          city: this.aprSave.profileDetails.city,
          state: this.aprSave.profileDetails.state,
          postalCode: this.aprSave.profileDetails.postalCode,
          member: this.aprSave.profileDetails.member,
          askPaymentDetails: this.aprSave.profileDetails.askPaymentDetails,
          askProfileDetails: this.aprSave.profileDetails.askProfileDetails,
          existsMainContact: this.aprSave.profileDetails.existsMainContact,
          existsPortalUser: this.aprSave.profileDetails.existsPortalUser,
          isPortalUser: this.aprSave.profileDetails.isPortalUser,
          isMainContact: this.aprSave.profileDetails.isMainContact,
          payLoc: this.aprSave.profileDetails.payLoc,
          isPortalUserOfAnyEntity: this.aprSave.profileDetails.isPortalUserOfAnyEntity,
          isOfacSanctioned: this.aprSave.profileDetails.isOfacSanctioned,
        },
        paymentDetails: {},
        lang: this.translateService.currentLang,
        isMultiAid: this.aprSave.isMultiAid ? this.aprSave.isMultiAid : false,
      };
    }

    if (this.termsAndConditionsSigned) {
      save.registrationDetails.termsAndConditionsSigned.id = this.termsAndConditionsSigned.id;
      save.registrationDetails.termsAndConditionsSigned.ipAddress = this.termsAndConditionsSigned.ipAddress;
      save.registrationDetails.termsAndConditionsSigned.timestamp = this.termsAndConditionsSigned.timestamp;
    }

    if (this.termsAndConditionsAdditionalSigned) {
      save.registrationDetails.termsAndConditionsAdditionalSigned.id = this.termsAndConditionsAdditionalSigned.id;
      save.registrationDetails.termsAndConditionsAdditionalSigned.ipAddress = this.termsAndConditionsAdditionalSigned.ipAddress;
      save.registrationDetails.termsAndConditionsAdditionalSigned.timestamp = this.termsAndConditionsAdditionalSigned.timestamp;
    }

    return save;
  }

  public sendGAInfo(field: any, key: string) {
    if (this.paymentForm.get(field).errors) {
      this.gaService.frontendValidationError(field, key);
    }
  }

  public sendLogInfo(field: any) {

    const dataLog: any = this.transformModel();

    dataLog.partnerName = this.partnerName;
    dataLog.agencyType = dataLog.registrationDetails.agencyType;
    dataLog.agencyCode = dataLog.registrationDetails.agencyCode;

    delete dataLog.registrationDetails;
    delete dataLog.profileDetails;
    delete dataLog.status;
    delete dataLog.isMultiAid;

    const request = {
      ofacRoutingValid: !this.paymentForm.get(field).hasError('ofacRoutingInvalid'),
      ofacSwiftValid: !this.paymentForm.get(field).hasError('ofacSwiftInvalid'),
    };

    const logServiceMessage = this.logService.getNewMessage();
    logServiceMessage.userId = localStorage.getItem(Constants.USER_ID);
    if (this.paymentForm.get(field).hasError('ofacRoutingInvalid')) {
      logServiceMessage.correlationId = localStorage.getItem(Constants.IS_OFAC_ROUTING_CORRELATION_ID);
    } else if (this.paymentForm.get(field).hasError('ofacSwiftInvalid')) {
      logServiceMessage.correlationId = localStorage.getItem(Constants.IS_OFAC_SWIFT_CORRELATION_ID);
    }
    logServiceMessage.actionId = 'CHECK_OFAC_DETAILS';
    logServiceMessage.resourceId = field.toUpperCase();
    logServiceMessage.message.custom.request = request;
    logServiceMessage.message.custom.response = dataLog;

    logServiceMessage.logLevel = LogLevel.INFO;
    logServiceMessage.message.text = 'Detected ofac swift/routing';
    logServiceMessage.userMetadata.custom.partnerId = dataLog.partnerId;
    logServiceMessage.userMetadata.custom.partnerName = dataLog.partnerName;
    logServiceMessage.userMetadata.custom.agencyId =
      Constants.NO_IATA ?
        dataLog.agencyType :
        dataLog.agencyType + '_' + dataLog.agencyCode;

    logServiceMessage.persist = true;

    this.logService.sendMessage(logServiceMessage);
  }


  /* // Useful for validation testing
    public getError(controlName: string): string {
     let error = '';
     const control = this.paymentForm.get(controlName);
     if (control && control.touched && control.errors != null) {
       error = JSON.stringify(control.errors);
       error = error + control.status;
     }
    return error;
   }*/

  refreshLanguage() {
    this.ibanCountryErrorDisplay = this.translateService.instant(this.defaultIbanCountryError);
    this.ibanMinLengthErrorDisplay = this.translateService.instant(this.defaultIbanMinLengthError);

    if (this.iban) {
      this.iban.clearAsyncValidators();
      this.iban.setAsyncValidators(
        IbanValidator.createValidator(this.aprService, this.translateService.currentLang, this.selectedCountry.value.code));
      this.iban.updateValueAndValidity();
    }
  }

  invalidOfacRoutingSwift(field: any): boolean {
    const isOfac: boolean = this.paymentForm.get(field) &&
                          ( this.paymentForm.get(field).hasError('ofacRoutingInvalid') || this.paymentForm.get(field).hasError('ofacSwiftInvalid')) ;

    const code: string | undefined = this.paymentForm.get(field) ? this.paymentForm.get(field).value : undefined ;

    if ( isOfac && code !== this.previousOfacCode) {
      this.previousOfacCode = code;

      this.sendLogInfo(field);
    }

    return isOfac;

  }

  get paymentMethod() { return this.paymentForm.get('paymentMethod'); }
  get selectedPaymentMethodCurrency() { return this.paymentForm.get('selectedPaymentMethodCurrency'); }
  get bankName() { return this.paymentForm.get('bankName'); }
  get selectedCountry() { return this.paymentForm.get('bankSelectedCountry'); }
  get address() { return this.paymentForm.get('bankAddress'); }
  get city() { return this.paymentForm.get('bankCity'); }
  get bankSelectedState() { return this.paymentForm.get('bankSelectedState'); }
  get zipCode() { return this.paymentForm.get('bankZipCode'); }
  get accountHolder() { return this.paymentForm.get('accountHolder'); }
  get accountNumber() { return this.paymentForm.get('accountNumber'); }
  get accountSuffix() { return this.paymentForm.get('accountSuffix'); }
  get accountType() { return this.paymentForm.get('accountType'); }
  get achRouting() { return this.paymentForm.get('achRouting'); }
  get bankCode() { return this.paymentForm.get('bankCode'); }
  get bankTransitNumber() { return this.paymentForm.get('bankTransitNumber'); }
  get branchCode() { return this.paymentForm.get('branchCode'); }
  get branchNumber() { return this.paymentForm.get('branchNumber'); }
  get bsbCode() { return this.paymentForm.get('bsbCode'); }
  get clabe() { return this.paymentForm.get('clabe'); }
  get iban() { return this.paymentForm.get('iban'); }
  get routingCode() { return this.paymentForm.get('routingCode'); }
  get swift() { return this.paymentForm.get('swift'); }

}
