import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from '@core/core.module';
import { NgxDesignSystemComponentsModule } from '@onyx/ngx-design-system-components';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AgencyRegistrationCompleteComponent } from './components/agency-registration-complete/agency-registration-complete.component';
import { AgencyRegistrationDetailsComponent } from './components/agency-registration-details/agency-registration-details.component';
import { AgencyRegistrationPaymentComponent } from './components/agency-registration-payment/agency-registration-payment.component';
import { AgencyRegistrationReconfirmationComponent } from './components/agency-registration-reconfirmation/agency-registration-reconfirmation.component';
import { AgencyRegistrationSessionExpirationComponent } from './components/agency-registration-session-expiration/agency-registration-session-expiration.component';
import { AgencyRegistrationComponent } from './components/agency-registration.component';
import { AgencyRegistrationIdentityDynamicComponent } from './components/agency-registration-identity-dynamic/agency-registration-identity-dynamic.component';
import { AgencyRegistrationOfacComponent } from './components/agency-registration-ofac/agency-registration-ofac.component';
import { AgencyRegistrationContainerComponent } from './components/agency-registration-container/agency-registration-container.component';
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AgencyEditComponent } from './components/agency-edit/agency-edit.component';
import { AgencyEditIdentityDynamicComponent } from './components/agency-edit-identity-dynamic/agency-edit-identity-dynamic.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AgencyEditAlertComponent } from './components/agency-edit-alert/agency-edit-alert.component';

@NgModule({
  declarations: [
    AppComponent,
    AgencyRegistrationComponent,
    AgencyRegistrationPaymentComponent,
    AgencyRegistrationDetailsComponent,
    AgencyRegistrationReconfirmationComponent,
    AgencyRegistrationCompleteComponent,
    AgencyRegistrationSessionExpirationComponent,
    AgencyRegistrationIdentityDynamicComponent,
    AgencyRegistrationOfacComponent,
    AgencyRegistrationContainerComponent,
    AgencyEditComponent,
    AgencyEditIdentityDynamicComponent,
    AgencyEditAlertComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    // app modules
    NgxDesignSystemComponentsModule,
    AppRoutingModule,
    TooltipModule.forRoot(),
  ],
  providers: [
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
