import { EXPIRATION_GDS } from './../../app.constants';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Constants } from '@app/app.constants';
import { PartnerResponse } from '@app/models/partner-response.model';

@Component({
  selector: 'ta-agency-registration-session-expiration',
  templateUrl: './agency-registration-session-expiration.component.html',
})
export class AgencyRegistrationSessionExpirationComponent implements OnInit {

  @Input() sessionExpiredTime: number;
  @Input() partnerIdInfo: PartnerResponse;
  @Output() reloadRegistration = new EventEmitter();

  public bodyTextNoGds: string;
  public bodyTextGds: string;

  constructor() { }

  ngOnInit() {
    const expirationGdsMsg = this.partnerIdInfo.messageConfig.filter(x => x.key === EXPIRATION_GDS);
    this.bodyTextGds = expirationGdsMsg[0].display;
  }

  reloadRegistrationForm() {
    this.reloadRegistration.emit();
  }

}
