import { FormControl, ValidatorFn } from '@angular/forms';
import { Constants } from '@app/app.constants';

export function officeIdsValidator(pattern: string): ValidatorFn {
  return (control: FormControl) => {
    if (control.value !== null && control.value !== undefined) {

      let ret = null;
      const dummy: string = control.value.replace(/\s/g, '');
      const match = dummy.search(pattern) > -1;

      if (!match) {
        ret = {'isInvalid': true};
      } else {
        if (control.value.split(',').length > Constants.MAX_OFFICE_IDS_ALLOWED) {
          ret = {'maxlengthInvalid': true};
        }
      }

      return ret;
    }
  };
}
