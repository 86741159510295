<div class="container-layout">
  <div class="container-fluid container-content">
    <div class="marquee">
      <i class="far fa-stopwatch marquee-icon"></i>
      <h1 class="marquee-heading"> {{'apr.sessionExpiredPage.header' | translate}}</h1>
      <h2 class="marquee-message">
        <p>
          {{'apr.sessionExpiredPage.bodyText1' | translate | replace: { sessionExpiredTime: sessionExpiredTime } }}
        </p>
        <p id="gdsMsg">{{bodyTextGds | translate}}</p>
      </h2>

      <button type="button" class="btn btn-primary marquee-primary-action" (click)="reloadRegistrationForm()"> {{'apr.sessionExpiredPage.returnButton' | translate}}</button>
    </div>
  </div>
</div>
