import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDesignSystemComponentsModule } from '@onyx/ngx-design-system-components';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PageSpinnerComponent } from './components/page-spinner/page-spinner.component';
import { PipesModule } from './pipes/pipes.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NoWhitespaceDirective } from './directives/no-white-space-validator.directive';
import { MaintenanceAppComponent } from './components/maintenance/maintenance.component';
import { InternalServerErrorComponent } from './components/internal-server-error/internal-server-error.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxDesignSystemComponentsModule,
    BsDatepickerModule.forRoot(),
    PipesModule,
    PopoverModule.forRoot(),
  ],
  declarations: [
    PageSpinnerComponent,
    NotFoundComponent,
    NoWhitespaceDirective,
    MaintenanceAppComponent,
    InternalServerErrorComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxDesignSystemComponentsModule,
    BsDatepickerModule,
    PipesModule,
    PopoverModule,
    PageSpinnerComponent,
    NotFoundComponent,
    NoWhitespaceDirective,
    MaintenanceAppComponent,
  ],
})
export class SharedModule { }
