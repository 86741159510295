import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MaintenanceAppComponent } from '@shared/components/maintenance/maintenance.component';
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';
import { AgencyRegistrationContainerComponent } from './components/agency-registration-container/agency-registration-container.component';
import { InternalServerErrorComponent } from './shared/components/internal-server-error/internal-server-error.component';


const routes: Routes = [
  {
    path: 'maintenance', component: MaintenanceAppComponent,
  },
  {
    path: 'agency-registration', component: AgencyRegistrationContainerComponent,
  },
  {
    path: 'internal-server-error', component: InternalServerErrorComponent,
  },
  {
    path: '**', component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
