<div class="card">
  <div class="card-header">
    <h2 class="card-title">{{'apr.registrationPayment.header' | translate}}</h2>
  </div>

  <div *ngIf="hasPaymentDetails" class="card-body">

    <p>
      {{'apr.registrationPayment.headerHavePayment' | translate}}
    </p>

    <div  class="card-body"> <!-- *ngIf="!isComplete" -->
      <form [formGroup]="paymentForm">

         <!-- Payment Methood -->
        <div class="form-group row">
          <label for="payment-method-input" class="col-lg-3 col-form-label">{{'apr.registrationPayment.paymentMethodLabel' | translate}}</label>
          <div class="col-lg-9">
            <input readonly id="payment-method-input" class="form-control-plaintext" placeholder="{{'apr.registrationPayment.paymentMethodPlaceHolder' | translate}}" formControlName="paymentMethod" name="paymentMethod"/>
          </div>
        </div>

        <!-- Currency -->
        <div class="form-group row" [ngClass]="{ 'form-group-invalid': isFieldValid('selectedPaymentMethodCurrency') }">
          <label for="currency-input" class="col-lg-3 col-form-label">{{'apr.registrationPayment.currencyLabel' | translate}}</label>
          <div class="col-lg-9">
            <select #currencyInput id="currency-input" class="form-control" formControlName="selectedPaymentMethodCurrency" name="selectedPaymentMethodCurrency" [ngClass]="{ 'is-invalid': isFieldValid('selectedPaymentMethodCurrency') }">
              <option [ngValue]="null" disabled>{{'apr.registrationPayment.pleaseSelectDropdown' | translate}}</option>
              <option *ngFor="let currency of (currencies$ | async)" [ngValue]="currency">{{currency.code}}</option>
            </select>

            <div class="invalid-feedback">
              <span>{{'apr.registrationPayment.currencyErrorText' | translate}}</span>
            </div>
          </div>
        </div>

        <!-- Bank name -->
        <div class="form-group row" [ngClass]="{ 'form-group-invalid': isFieldValid('bankName') }">
          <label for="bank-name-input" class="col-lg-3 col-form-label">{{'apr.registrationPayment.bankNameLabel' | translate}}</label>
          <div class="col-lg-9">
            <input id="bank-name-input" type="text" taUpperCase taNoWhitespace class="form-control"
            placeholder="{{'apr.registrationPayment.bankNameLabel' | translate}}" formControlName="bankName" name="bankName"
            [ngClass]="{ 'is-invalid': isFieldValid('bankName') }"
            (blur)="sendGAInfo('bankName','bankNameInvalid')"/>
            <span class="invalid-feedback" *ngIf="this.bankName.errors?.required">
              {{'apr.registrationPayment.bankNameErrorText' | translate}}
            </span>
            <span class="invalid-feedback" *ngIf="this.bankName.errors?.bankNameInvalid && !this.bankName.errors?.latinPatternError && !this.bankName.errors?.maxlength">
             {{'apr.registrationPayment.bankNameInvalidError' | translate}}

            </span>
            <span class="invalid-feedback" *ngIf="this.bankName.errors?.latinPatternError">
              {{'apr.validCharset' | translate}}
            </span>
            <span class="invalid-feedback" *ngIf="this.bankName.errors?.maxlength">
              {{'apr.maxLengthErrorText' | translate}}
            </span>
          </div>
        </div>

        <!-- Bank Country -->
        <div class="form-group row" [ngClass]="{ 'form-group-invalid': isFieldValid('bankSelectedCountry') }">
          <label for="country-input" class="col-lg-3 col-form-label">{{'apr.registrationPayment.bankCountryLabel' | translate}}</label>
          <div class="col-lg-9">
            <select #countryInput id="country-input" class="form-control" formControlName="bankSelectedCountry" name="bankSelectedCountry" (change)="onBankCountryChange($event)" [compareWith]="compareFn"  [ngClass]="{ 'is-invalid': isFieldValid('bankSelectedCountry') }">
              <option [ngValue]="null" disabled>{{'apr.registrationPayment.pleaseSelectDropdown' | translate}}</option>
              <option *ngFor="let country of (bankCountries$ | async)" [ngValue]="country">{{country.name}}</option>
            </select>

            <div class="invalid-feedback">
              <span>{{'apr.registrationPayment.bankCountryErrorText' | translate}}</span>
            </div>
          </div>
        </div>

        <!--Complete Address container -->
          <!-- Address -->
            <div class="form-group row" [ngClass]="{ 'form-group-invalid': isFieldValid('bankAddress') }">
              <label for="address-input" class="col-lg-3 col-form-label">{{'apr.registrationPayment.bankAddressLabel' | translate}}</label>
              <div class="col-lg-9">
                <input id="address-input" type="text" taUpperCase taNoWhitespace class="form-control" placeholder="{{'apr.registrationPayment.bankAddressPlaceHolder' | translate}}"  formControlName="bankAddress" name="bankAddress" [ngClass]="{ 'is-invalid': isFieldValid('bankAddress') }"
                (blur)="sendGAInfo('bankAddress','bankAddressInvalid')"/>
                <span class="invalid-feedback" *ngIf="!paymentForm.get('bankAddress').errors?.latinPatternError && !paymentForm.get('bankAddress').errors?.maxlength">
                  {{'apr.registrationPayment.bankAddressErrorText' | translate}}
                </span>
                <span class="invalid-feedback" *ngIf="paymentForm.get('bankAddress').errors?.latinPatternError">
                  {{'apr.validCharset' | translate}}
                </span>
                <span class="invalid-feedback" *ngIf="paymentForm.get('bankAddress').errors?.maxlength">
                  {{'apr.maxLengthErrorText' | translate}}
                </span>
              </div>
            </div>

          <!-- City -->
            <div class="form-group row" [ngClass]="{ 'form-group-invalid': isFieldValid('bankCity') }">
              <label for="city-input" class="col-lg-3 col-form-label">{{'apr.registrationPayment.bankCityLabel' | translate}}</label>
              <div class="col-lg-9">
                <input id="city-input" type="text" taUpperCase taNoWhitespace class="form-control" placeholder="{{'apr.registrationPayment.bankCityPlaceHolder' | translate}}" formControlName="bankCity" name="bankCity" [ngClass]="{ 'is-invalid': isFieldValid('bankCity') }"
                (blur)="sendGAInfo('bankCity','bankCityInvalid')"/>
                <div class="invalid-feedback">
                  <span *ngIf="!paymentForm.get('bankCity').errors?.latinPatternError">
                    {{'apr.registrationPayment.bankCityErrorText' | translate}}
                  </span>
                  <span *ngIf="paymentForm.get('bankCity').errors?.latinPatternError">
                    {{'apr.validCharset' | translate}}
                  </span>
                </div>
              </div>
            </div>

          <!-- State -->
          <div *ngIf="hasBankStates" class="form-group row" [ngClass]="{ 'form-group-invalid': isFieldValid('bankSelectedState') }">
            <label for="state-input" class="col-lg-3 col-form-label">
              {{'apr.registrationPayment.bankStateProvinceRegionLabel' | translate}}
            </label>
            <div class="col-lg-9">
              <select id="state-input" class="form-control"  formControlName="bankSelectedState" name="bankSelectedState" [ngClass]="{ 'is-invalid': isFieldValid('bankSelectedState') }">
                <option [ngValue]="undefined" disabled>{{'apr.registrationPayment.pleaseSelectDropdown' | translate}}</option>
                <option *ngFor="let state of (bankStates$ | async)" [ngValue]="state" >{{state.name}}</option>
              </select>

              <div class="invalid-feedback">
                <span>{{'apr.registrationPayment.bankStateProvinceRegionErrorText' | translate}}</span>
              </div>
            </div>
          </div>

          <!-- Postal Zip -->
         <div class="form-group row mb-2" [ngClass]="{ 'form-group-invalid': isFieldValid('bankZipCode') }">
            <label for="postal-code-input" class="col-lg-3 col-form-label">
              {{'apr.registrationPayment.postalBankZipCodeLabel' | translate}}
              <small class="form-text text-muted">{{'apr.registrationPayment.optional' | translate}}</small>
            </label>
            <div class="col-lg-9">
              <input id="postal-code-input" type="text" taUpperCase taNoWhitespace class="form-control" placeholder="{{'apr.registrationPayment.postalBankZipCodePlaceHolder' | translate}}"  formControlName="bankZipCode" name="bankZipCode" [ngClass]="{ 'is-invalid': isFieldValid('bankZipCode') }"
              (blur)="sendGAInfo('bankZipCode','bankZipCodeInvalid')"/>
              <div class="invalid-feedback">
                <span *ngIf="!paymentForm.get('bankZipCode').errors?.latinPatternError">
                  {{'apr.registrationPayment.postalBankZipCodeErrorText' | translate}}
                </span>
                <span *ngIf="paymentForm.get('bankZipCode').errors?.latinPatternError">
                  {{'apr.validCharset' | translate}}
                </span>
              </div>
            </div>
          </div>


          <!-- Containers by special country -->
          <div *ngFor="let item of fieldRulesList">
             <div class="form-group row" *ngIf="item.fieldName" [ngClass]="{ 'form-group-invalid': isFieldValid(item.fieldName) }">
              <label class="col-lg-3 col-form-label">{{item.fieldLabel}}</label>
              <div class="col-lg-9">
                <input *ngIf="((item.fieldName).indexOf('accountType') == -1)" type="text" taUpperCase taNoWhitespace class="form-control" placeholder={{item.fieldLabel}} formControlName="{{item.fieldName}}" name="{{item.fieldName}}" [ngClass]="{ 'is-invalid': isFieldValid(item.fieldName) }"
                (blur)="sendGAInfo(item.fieldName,item.fieldName+'Invalid');"/>

                <select *ngIf="((item.fieldName).indexOf('accountType') != -1)" id="accountType-input" class="form-control"  formControlName="accountType" name="accountType" [ngClass]="{ 'is-invalid': isFieldValid('accountType') }">
                  <option [ngValue]="null" disabled>{{'apr.registrationPayment.pleaseSelectDropdown' | translate}}</option>
                  <option *ngFor="let accountType of accountTypesList" [ngValue]="accountType" >{{accountType.name}}</option>
                </select>

                <!-- IBAN Validation -->
                <span *ngIf="((item.fieldName).indexOf('iban') != -1)">
                  <span *ngIf="this.iban && this.iban.hasError('ibanInvalid')">
                    <span class="invalid-feedback" *ngIf="this.iban.hasError('errorIbanCountry')">{{this.ibanCountryErrorDisplay}}</span>
                    <span class="invalid-feedback" *ngIf="this.iban.hasError('errorLength')">{{this.iban.getError('errorLength')}}</span>
                    <span class="invalid-feedback" *ngIf="this.iban.hasError('errorMin')">{{this.ibanMinLengthErrorDisplay}}</span>
                    <span class="invalid-feedback" *ngIf="this.iban.hasError('errorMax')">{{this.iban.getError('errorMax')}}</span>
                    <span class="invalid-feedback" *ngIf="this.iban.hasError('errorNull')">{{this.iban.getError('errorNull')}}</span>
                    <span class="invalid-feedback" *ngIf="this.iban.hasError('errorFormatChar')">{{this.iban.getError('errorFormatChar')}}</span>
                    <span class="invalid-feedback" *ngIf="this.iban.hasError('errorCheckDigit')">{{this.iban.getError('errorCheckDigit')}}</span>
                    <span class="invalid-feedback" *ngIf="this.iban.hasError('errorConfigMissed')">{{this.iban.getError('errorConfigMissed')}}</span>
                    <span class="invalid-feedback" *ngIf="this.iban.hasError('exception')">{{'apr.registrationPayment.ibanInvalid' | translate }}</span>
                  </span>

                  <span class="invalid-feedback" *ngIf="this.iban && !this.iban.hasError('ibanInvalid')">
                    {{'apr.registrationPayment.bankRulesError' | translate | replace: { field: item.fieldLabel } }}
                  </span>
                </span>

                <!-- OFAC Routing Validation -->
                <span *ngIf="((item.fieldName).indexOf('routingCode') != -1)">
                  <span class="invalid-feedback" *ngIf="invalidOfacRoutingSwift(item.fieldName)">
                    {{'apr.registrationPayment.ofacRouringErrorText' | translate}}
                  </span>
                </span>

                <!-- OFAC Swift Validation -->
                <span *ngIf="((item.fieldName).indexOf('swift') != -1)">
                  <span class="invalid-feedback" *ngIf="invalidOfacRoutingSwift(item.fieldName)">
                    {{'apr.registrationPayment.ofacSwiftErrorText' | translate}}
                  </span>
                </span>

                <!-- Banking validations -->
                <span *ngIf="((item.fieldName).indexOf('iban') == -1) && ((item.fieldName).indexOf('bankName') == -1)">
                  <span class="invalid-feedback" *ngIf="((this.paymentForm.get(item.fieldName)) && (this.paymentForm.get(item.fieldName)).errors?.required)">
                    {{'apr.registrationPayment.bankRulesError' | translate | replace: { field: item.fieldLabel } }}
                  </span>
                  <span class="invalid-feedback" *ngIf="((this.paymentForm.get(item.fieldName)) && (this.paymentForm.get(item.fieldName)).errors?.pattern)">
                    {{'apr.registrationPayment.fieldInvalid' | translate | replace: { field: item.fieldLabel } }}
                  </span>
                  <span class="invalid-feedback" *ngIf="((this.paymentForm.get(item.fieldName)) && (this.paymentForm.get(item.fieldName)).errors?.latinPatternError)">
                    {{'apr.validCharset' | translate}}
                  </span>
                </span>
              </div>
            </div>
          </div>
      </form>
    </div>
  </div>

 <!--<span>{{ getError('bankName')}}</span>-->

  <!-- Show message -->
  <div *ngIf="!hasPaymentDetails" class="card-body">
    <p>{{'apr.registrationPayment.bodyText1' | translate}}</p>
    <p class="mb-0" [innerHtml]="'apr.registrationPayment.bodyText2' | translate | replace: {URL_CUSTOMER_SUPPORT: customerSupportUrl}"></p>
  </div>

  <!-- footer -->
  <div class="card-footer">
    <div class="row">
      <div class="offset-lg-8 col-lg-4">
        <button *ngIf="isValidating" type="button" class="btn btn-primary btn-block" disabled>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{'apr.registrationIdentity.verifyingSubmitBtnLabel' | translate}}
        </button>

        <button *ngIf="!isValidating" type="submit" class="btn btn-primary btn-block" (click)="onContinueClicked($event)">{{'apr.registrationPayment.completeSubmitBtnLabel' | translate}}</button>

        <div *ngIf="isFormValid()" class="form-group form-group-invalid mb-0">
          <div class="invalid-feedback">
            <span>{{'apr.registrationIdentity.submitErrorText' | translate}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
