import {
  HttpBackend,
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '@app/app.constants';
import { MessagesUtilsService } from '@core/services/messages-utils.service';
import { LogLevel, LogServiceClientService } from '@onyx/log-service-client-11';
import { Message } from '@shared/models';
import { Observable, Subscription, of, throwError } from 'rxjs';
import { catchError, concatMap, delay, retryWhen } from 'rxjs/operators';

export const RETRY_COUNT = 2;
export const RETRY_WAIT_MILLISECONDS = 500;

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor, OnDestroy {
  private subscription: Subscription = new Subscription();
  private httpWithoutInterceptor: HttpClient;

  constructor(private messageService: MessagesUtilsService,
    private logService: LogServiceClientService,
    private router: Router,
    private httpBackend: HttpBackend) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retryWhen(httpErrorResponse =>
        httpErrorResponse.pipe(
          concatMap((error, count) => {
            if (count <= RETRY_COUNT && error.status === 0 && error.error.isTrusted) {
              const logServiceMessage = this.logService.getNewMessage();
              logServiceMessage.userId = localStorage.getItem(Constants.USER_ID);
              try {
                logServiceMessage.correlationId = error.headers.get('x-correlation-id');
              } catch (e) {
                // console.log('Error getting x-correlation-id header');
              }
              if (logServiceMessage.correlationId == null) {
                logServiceMessage.correlationId = 'UNDEFINED';
              }
              logServiceMessage.logLevel = LogLevel.WARN;
              logServiceMessage.actionId = 'RETRY_REQUEST';
              logServiceMessage.resourceId = req.url;
              logServiceMessage.message.text = 'Retry (attempt ' + count + ') ' + req.url;
              logServiceMessage.message.custom.request = req;
              logServiceMessage.message.custom.response = error;

              // console.log ("logService", logServiceMessage);

              this.logService.sendMessage(logServiceMessage);


              return of(error);
            }
            return throwError(error);
          }),
          delay(RETRY_WAIT_MILLISECONDS)
        )
      ),
      catchError((error: HttpErrorResponse) => {
        const message: Message = new Message();
        message.type = 'danger';

        // Check URL is not Log Service to prevent endless loop
        if (req.url.includes(this.logService.getUrlLogService())) {
          message.code = 'ERROR_LOG_SERVICE_001';
          message.errorObject = error;
          // console.log('Error calling Log Service');
        } else {
          const logServiceMessage = this.logService.getNewMessage();
          logServiceMessage.userId = localStorage.getItem(Constants.USER_ID);
          try {
            logServiceMessage.correlationId = error.headers.get('x-correlation-id');
          } catch (e) {
            // console.log('Error getting x-correlation-id header');
          }
          if (logServiceMessage.correlationId == null) {
            logServiceMessage.correlationId = 'UNDEFINED';
          }
          logServiceMessage.logLevel = LogLevel.ERROR;
          logServiceMessage.actionId = 'BACKEND_REQUEST';
          logServiceMessage.resourceId = req.url;
          logServiceMessage.message.text = 'Error calling ' + req.url;
          logServiceMessage.message.custom.request = req;
          logServiceMessage.message.custom.response = error;

          // console.log ('logService', logServiceMessage);

          this.logService.sendMessage(logServiceMessage);
        }

        if (error.error instanceof ErrorEvent) {
          // client-side error
          message.code = 'ERROR-001';
          message.errorObject = error;

        } else {
          // server-side error
          if (error.error && error.error.messageError) {
            message.code = error.error.messageError;
            message.errorObject = error.error;
            this.messageService.addMessage(message);

            if (error.error.messageError === 'ERROR_MAINT_0001') {
              this.redirectToMaintPage();
            } else {
              this.redirectToInternalServerErrorPage();
            }
          } else {
            this.subscription.add(
              this.isMaintenance().subscribe((response: boolean) => {
                if (response) {
                  this.redirectToMaintPage();
                } else {
                  if (message.code !== 'ERROR_LOG_SERVICE_001') {
                    message.code = 'ERROR-002';
                    message.errorObject = error;
                    this.redirectToInternalServerErrorPage();
                  }
                }
              }
              ));
          }
          return throwError(message);
        }
      })
    );
  }

  redirectToMaintPage() {
    this.router.navigate(['maintenance']).then(() => {
    });
  }

  redirectToInternalServerErrorPage() {
    this.router.navigate(['internal-server-error']).then(() => {
    });
  }

  private isMaintenance(): Observable<boolean> {
    return this.httpWithoutInterceptor.get<boolean>(Constants.URL_MAINTENANCE);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
