import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Constants } from '@app/app.constants';
import { AprSave } from '@app/models/apr-save.model';
import { PartnerResponse } from '@app/models/partner-response.model';
import { AprService } from '@app/services/apr.service';
import { TitleService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { TermsAndConditions } from '@app/models/terms-and-conditions.model';


@Component({
  selector: 'ta-agency-registration',
  templateUrl: './agency-registration.component.html',
})
export class AgencyRegistrationComponent implements OnInit, OnDestroy, OnChanges {

  // public urlWalkthrough: String = Constants.URL_APR_WALKTHROUGH;
  public isBlockingPageNavigation = true;

  @Input() showSessionExpiredPage: boolean;
  @Input() sessionExpiredTime: number;
  @Input() partnerIdInfo: PartnerResponse;
  @Output() reloadAppComponent = new EventEmitter();
  @Output() moveToTop = new EventEmitter();

  registrationForm: FormGroup;
  toStep2 = false;
  toStep3 = false;
  showFormPayment: boolean;
  showReConfirmationPage = false;
  showCompletePage = false;
  showOfacPage = false;

  otaId: string;
  partnerName: string;
  partnerId: string;
  userGuideLink: string;
  needAssitanceLink: string;
  faqLink: string;

  emailContact: String = null;

  aprSave: AprSave = null;
  registrationId: Observable<number> = this.aprService.registrationId$;

  language: string;
  activeLang = Constants.LANG_DEFAULT.name;
  activeLangCode = Constants.LANG_DEFAULT.code;
  languageList = Constants.APR_LANGUAGES;

  termsAndConditionsSigned: TermsAndConditions;
  termsAndConditionsAdditionalSigned: TermsAndConditions;


  constructor(private tittleService: TitleService,
    private translateService: TranslateService,
    private aprService: AprService,
    private fb: FormBuilder,
    private gaService: GoogleAnalyticsService

  ) { }


  ngOnInit() {
    this.tittleService.setPageTitle('', true);

    this.initFrom();

    this.language = localStorage.getItem(Constants.LANG_KEY);

    this.needAssitanceLink = Constants.URL_CUSTOMER_SUPPORT;

    if (this.language && Constants.APR_LANGUAGES.find(lang => lang.code === this.language)) {
      this.useLanguage(this.language, false);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('showSessionExpiredPage')) {
      const change = changes['showSessionExpiredPage'];
      this.showSessionExpiredPage = change.currentValue;
      this.moveToTop.emit();
    } else if (changes.partnerIdInfo.currentValue !== undefined) {
      this.partnerIdInfo = changes.partnerIdInfo.currentValue;
      this.partnerName = this.partnerIdInfo.partnerName;
      this.partnerId = this.partnerIdInfo.partnerId;
      this.otaId = this.otaId;
      this.setUserGuideLinkByLanguage();
      if (this.partnerIdInfo.faqLink) {
        this.faqLink = `${environment.cdnUrl}${this.partnerName.toLowerCase()}/${this.partnerIdInfo.faqLink}`;
      }
    }
  }

  initFrom() {
    this.registrationForm = this.fb.group({

    });
  }

  onSubmit(): void {
    // Send data to server
  }

  // Abandonment blocking
  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: BeforeUnloadEvent): void {
    if (event !== undefined
      && this.isBlockingPageNavigation
      && !this.showCompletePage
      && !this.showSessionExpiredPage
      && !this.showOfacPage) {

      // Cancel the event (interrupt page navigation)
      event.preventDefault();

      // Display a message (if the browser allows it)
      event.returnValue = this.translateService.instant('abandonAlert');
    }
  }

  moveToStep2(): void {
    this.toStep2 = true;
    const element = document.getElementById('step2card');
    if (element !== null) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }

  moveToStep3($event): void {
    this.toStep3 = true;
    this.toStep2 = true;
    this.showFormPayment = $event;
    const element = document.getElementById('step3card');
    if (element !== null) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }

  setAprSave($event): void {
    this.aprSave = $event;
    this.registrationId = $event.registrationId;
  }

  setTermsAndConditionsSigned($event): void {
    this.termsAndConditionsSigned = $event;
  }

  setTermsAndConditionsAdditionalSigned($event): void {
    this.termsAndConditionsAdditionalSigned = $event;
  }

  showReconfirmationPage(): void {
    this.showReConfirmationPage = true;
    this.moveToTop.emit();
  }

  setShowCompletePage(): void {
    this.showCompletePage = true;
    this.moveToTop.emit();
  }

  setEmailContact($event): void {
    this.emailContact = $event;
  }

  public ngOnDestroy(): void {
    localStorage.removeItem(Constants.TOKEN_KEY);
    window.removeEventListener('beforeunload', null);
  }

  reloadRegistration($event): void {
    this.reloadAppComponent.emit();
  }

  public sendGAInfo(link: string) {
    if (link.indexOf('g') !== -1) {
      this.gaService.clickUserGuide();
    }
    if (link.indexOf('e') !== -1) {
      this.gaService.clickSupportEmail();
    }
    if (link.indexOf('f') !== -1) {
      this.gaService.clickFaq();
    }
  }

  public useLanguage(code: string, updateLink: boolean) {
    this.translateService.use(code);
    localStorage.setItem(Constants.LANG_KEY, code);

    const lang = this.languageList.filter((x) => x.code === code);
    this.activeLang = lang[0].name;
    this.activeLangCode = lang[0].code;

    if (updateLink){
      this.setUserGuideLinkByLanguage();
    }
  }

  setUserGuideLinkByLanguage() {
    const userGuideLinkDummy = this.partnerIdInfo.userGuideLink.split('.');
    const userGuideByLanguage = `${userGuideLinkDummy[0]}_${this.activeLangCode.toUpperCase()}.${userGuideLinkDummy.slice(-1)[0]}`;

    this.userGuideLink = `${environment.cdnUrl}${this.partnerName.toLocaleLowerCase()}/${userGuideByLanguage} `;
  }

  setShowOfacPage(): void {
    this.showOfacPage = true;
    this.moveToTop.emit();
  }

  partnerIdChange($event) {
    if ($event) {
      this.partnerId = $event;
      this.partnerIdInfo.partnerId = this.partnerId;
    }

  }

}
