import { Component, OnDestroy, OnInit } from '@angular/core';
import { Constants } from '@app/app.constants';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ta-maintenance',
  templateUrl: './maintenance.component.html',
})
export class MaintenanceAppComponent implements OnInit, OnDestroy {

  customerSupportUrl: string = Constants.URL_CUSTOMER_SUPPORT;
  private httpWithoutInterceptor: HttpClient;
  subscription: Subscription;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private httpBackend: HttpBackend) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
  }

  ngOnInit() {

    this.subscription = new Subscription();
    this.subscription.add(
      this.httpWithoutInterceptor.get<boolean>(Constants.URL_MAINTENANCE).subscribe(response => {
        if (!response) {
          this.router.navigate(['notfound']);
        }
      }));

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
