<div class="card">
  <div class="card-header">
    <h2 class="card-title">{{'apr.registrationDetails.header' | translate}}</h2>
  </div>
  <div *ngIf="!isComplete" class="card-body">
    <form [formGroup]="detailsForm">

      <!-- Company Name -->
      <div *ngIf="autofill">
        <div *ngIf="!isComplete">
          <div class="form-group row">
            <label for="company-name-input" class="col-lg-3 col-form-label">{{'apr.registrationDetails.companyNameLabel' | translate}}</label>
            <div class="col-lg-9">
              <p id="company-name-text" class="form-control-plaintext">{{this.localData.companyName}}</p>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!autofill">
        <div class="form-group row" [ngClass]="{ 'form-group-invalid': isFieldValid('companyName') }">
          <label for="company-name-input" class="col-lg-3 col-form-label">{{'apr.registrationDetails.companyNameLabel' | translate}}</label>
          <div class="col-lg-9">
            <div *ngIf="!isComplete">
                <input id="company-name-input" #companyNameInput type="text" taUpperCase taNoWhitespace class="form-control" placeholder="{{'apr.registrationDetails.companyNamePlaceHolder' | translate}}" formControlName="companyName" name="companyName" [ngClass]="{ 'is-invalid':  isFieldValid('companyName') }"
                (blur)="sendGAInfo('companyName','companyNameInvalid')"/>

                <span class="invalid-feedback" *ngIf="!detailsForm.get('companyName').errors?.latinPatternError && !detailsForm.get('companyName').errors?.maxlength">
                  {{'apr.registrationDetails.companyNameErrorText' | translate}}
                </span>
                <span class="invalid-feedback" *ngIf="detailsForm.get('companyName').errors?.latinPatternError">
                  {{'apr.validCharset' | translate}}
                </span>
                <span class="invalid-feedback" *ngIf="detailsForm.get('companyName').errors?.maxlength">
                  {{'apr.maxLengthErrorText' | translate}}
                </span>
            </div>
          </div>
        </div>
      </div>


      <!-- Contact Name -->
     <div class="form-group row" [ngClass]="{ 'form-group-invalid': isFieldValid('contactName') }">
        <label for="contact-name-input" class="col-lg-3 col-form-label">{{'apr.registrationDetails.contactNameLabel' | translate}}</label>
        <div class="col-lg-9">
          <div *ngIf="!isComplete">
            <input id="contact-name-input" #contactNameInput type="text" taUpperCase taNoWhitespace class="form-control" placeholder="{{'apr.registrationDetails.contactNamePlaceHolder' | translate}}"  formControlName="contactName" name="contactName" [ngClass]="{ 'is-invalid': isFieldValid('contactName') }"
            (blur)="sendGAInfo('contactName','contactNameInvalid')"/>
            <div class="invalid-feedback">
              <span *ngIf="!detailsForm.get('contactName').errors?.latinPatternError">
                {{'apr.registrationDetails.contactNameErrorText' | translate}}
              </span>
              <span *ngIf="detailsForm.get('contactName').errors?.latinPatternError">
                {{'apr.validCharset' | translate}}
              </span>
            </div>
          </div>
        </div>
      </div>

       <!-- Country -->
       <div class="form-group row" [ngClass]="{ 'form-group-invalid': isFieldValid('selectedCountry') }">
        <label for="country-input" class="col-lg-3 col-form-label">{{'apr.registrationDetails.countryLabel' | translate}}</label>
        <div class="col-lg-9">
          <div *ngIf="!isComplete">
            <select #countryInput id="country-input" class="form-control" formControlName="selectedCountry" name="selectedCountry" (change)="onCountryChange($event)" [compareWith]="compareFn" [ngClass]="{ 'is-invalid': isFieldValid('selectedCountry') }">
              <option [ngValue]="null" disabled>{{'apr.registrationDetails.pleaseSelectDropdown' | translate}}</option>
              <option *ngFor="let country of (countries$ | async)" [ngValue]="country">{{country.name}}</option>
            </select>

            <div class="invalid-feedback">
              <span>{{'apr.registrationDetails.countryErrorText' | translate}}</span>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="hasCountrySelection()">
        <!-- Address -->
          <div class="form-group row" [ngClass]="{ 'form-group-invalid': isFieldValid('address') }">
            <label for="address-input" class="col-lg-3 col-form-label">{{'apr.registrationDetails.addressLabel' | translate}}</label>
            <div class="col-lg-9">
              <div *ngIf="!isComplete">
                <input id="address-input" type="text" taNoWhitespace taNoWhitespace class="form-control" placeholder="{{'apr.registrationDetails.addressPlaceHolder' | translate}}"  formControlName="address" name="address" [ngClass]="{ 'is-invalid': isFieldValid('address') }"
                (blur)="sendGAInfo('address','addressInvalid')"/>
                <span class="invalid-feedback" *ngIf="!detailsForm.get('address').errors?.latinPatternError && !detailsForm.get('address').errors?.maxlength">
                  {{'apr.registrationDetails.addressErrorText' | translate}}
                </span>
                <span class="invalid-feedback" *ngIf="detailsForm.get('address').errors?.latinPatternError">
                  {{'apr.validCharset' | translate}}
                </span>
                <span class="invalid-feedback" *ngIf="detailsForm.get('address').errors?.maxlength">
                {{'apr.maxLengthErrorText' | translate}}
                </span>
              </div>
            </div>
          </div>

        <!-- City -->
          <div class="form-group row" [ngClass]="{ 'form-group-invalid': isFieldValid('city') }">
            <label for="city-input" class="col-lg-3 col-form-label">{{'apr.registrationDetails.cityLabel' | translate}}</label>
            <div class="col-lg-9">
              <div *ngIf="!isComplete">
                <input id="city-input" type="text" taUpperCase taNoWhitespace class="form-control" placeholder="{{'apr.registrationDetails.cityPlaceHolder' | translate}}" formControlName="city" name="city" [ngClass]="{ 'is-invalid': isFieldValid('city') }"
                (blur)="sendGAInfo('city','cityInvalid')"/>
                <span class="invalid-feedback" *ngIf="!detailsForm.get('city').errors?.latinPatternError && !detailsForm.get('city').errors?.maxlength">
                  {{'apr.registrationDetails.cityErrorText' | translate}}
                </span>
                <span class="invalid-feedback" *ngIf="detailsForm.get('city').errors?.latinPatternError">
                  {{'apr.validCharset' | translate}}
                </span>
                <span class="invalid-feedback" *ngIf="detailsForm.get('city').errors?.maxlength">
                  {{'apr.maxLengthErrorText' | translate}}
                </span>
              </div>
            </div>
          </div>

        <!-- State -->
        <div *ngIf="hasStates" class="form-group row" [ngClass]="{ 'form-group-invalid': isFieldValid('selectedState') }">
          <label for="state-input" class="col-lg-3 col-form-label">
            {{'apr.registrationDetails.stateProvinceRegionLabel' | translate}}
          </label>
          <div class="col-lg-9">
            <div *ngIf="!isComplete">
              <select id="state-input" class="form-control"  formControlName="selectedState" name="selectedState" [ngClass]="{ 'is-invalid': isFieldValid('selectedState') }">
                <option [ngValue]="undefined" disabled>{{'apr.registrationDetails.pleaseSelectDropdown' | translate}}</option>
                <option *ngFor="let state of (states$ | async)" [ngValue]="state" >{{state.name}}</option>
              </select>

              <div class="invalid-feedback">
                <span>{{'apr.registrationDetails.stateProvinceRegionErrorText' | translate}}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Postal Zip -->
        <div class="form-group row mb-2" [ngClass]="{ 'form-group-invalid': isFieldValid('zipCode') }">
          <label for="postal-code-input" class="col-lg-3 col-form-label">
            {{'apr.registrationDetails.postalZipCodeLabel' | translate}}
          </label>
          <div class="col-lg-9">
            <div *ngIf="!isComplete">
              <input id="postal-code-input" type="text" taUpperCase taNoWhitespace class="form-control" placeholder="{{'apr.registrationDetails.postalZipCodePlaceHolder' | translate}}"  formControlName="zipCode" name="zipCode" [ngClass]="{ 'is-invalid': isFieldValid('zipCode') }"
              (blur)="sendGAInfo('zipCode','zipCodeInvalid')"/>
              <div class="invalid-feedback">
                <span *ngIf="!detailsForm.get('zipCode').errors?.latinPatternError">
                  {{'apr.registrationDetails.postalZipCodeErrorText' | translate}}
                </span>
                <span *ngIf="detailsForm.get('zipCode').errors?.latinPatternError">
                  {{'apr.validCharset' | translate}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div> <!-- container -->
    </form>
  </div> <!--card body -->

  <div *ngIf="isComplete" class="card-body">
    <div class="form-group row">
      <label for="company-name-text" class="col-lg-3 col-form-label">{{'apr.registrationDetails.companyNameLabel' | translate}}</label>
      <div class="col-lg-9">
        <p id="company-name-text" class="form-control-plaintext">{{companyName.value}}</p>
      </div>
    </div>

    <div class="form-group row">
      <label for="contact-name-text" class="col-lg-3 col-form-label">{{'apr.registrationDetails.contactNameLabel' | translate}}</label>
      <div class="col-lg-9">
        <p id="contact-name-text" class="form-control-plaintext">{{contactName.value}}</p>
      </div>
    </div>

    <div class="form-group row mb-0">
      <label for="address-text" class="col-lg-3 col-form-label">{{'apr.registrationDetails.addressLabel' | translate}}</label>
      <div class="col-lg-9">
        <p id="address-text" class="form-control-plaintext mb-0">
          {{address.value}}<br />
          <span *ngIf="hasStates">{{city.value}}, {{stateName}} {{zipCode.value}}<br /></span>
          <span *ngIf="!hasStates">{{zipCode.value}} {{city.value}}<br /></span>
          {{selectedCountry.value.name}}
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="!isComplete" class="card-footer">
    <div class="row">
      <div class="offset-lg-8 col-lg-4">
        <button *ngIf="isValidating" type="button" class="btn btn-primary btn-block" disabled>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{'apr.registrationIdentity.verifyingSubmitBtnLabel' | translate}}
        </button>
        <button *ngIf="!isValidating" type="submit" class="btn btn-primary btn-block" (click)="onContinueClicked($event)"> {{'apr.registrationIdentity.continueSubmitBtnLabel' | translate}}</button>
        <div *ngIf="isFormValid()" class="form-group form-group-invalid mb-0">
          <div class="invalid-feedback">
            <span>{{'apr.registrationIdentity.submitErrorText' | translate}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
