import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AprActivatedResponse } from '@app/models/apr-activated-response.model';
import { AprAgencyInfo } from '@app/models/apr-agency-info.model';
import { AprSave } from '@app/models/apr-save.model';
import { BankRules } from '@app/models/bank-rules.model';
import { IbanResponse } from '@app/models/iban-response.model';
import { PartnerResponse } from '@app/models/partner-response.model';
import { SpinnerUtilsService } from '@core/services';
import { Country, Currency, Dropdown } from '@shared/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Constants } from '../app.constants';
import { LogServiceClientService, LogLevel } from '@onyx/log-service-client-11';
import { TermsAndConditions } from '@app/models/terms-and-conditions.model';


@Injectable({
  providedIn: 'root',
})
export class AprService {
  constructor(
    private httpClient: HttpClient,
    private spinnerService: SpinnerUtilsService,
    private router: Router,
    private logService: LogServiceClientService
  ) { }

  private gdsOkSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public gdsOk$: Observable<boolean> = this.gdsOkSubject.asObservable();

  private registrationIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public registrationId$: Observable<number> = this.registrationIdSubject.asObservable();

  private aprAgencyInfoSubject: BehaviorSubject<AprAgencyInfo> = new BehaviorSubject<AprAgencyInfo>(null);
  public aprAgencyInfo$: Observable<AprAgencyInfo> = this.aprAgencyInfoSubject.asObservable();

  private finishProcessSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public finishProcessSubject$: Observable<boolean> = this.finishProcessSubject.asObservable();

  private bankRulesSubject: BehaviorSubject<BankRules> = new BehaviorSubject<BankRules>(null);
  public bankRules$: Observable<BankRules> = this.bankRulesSubject.asObservable();

  private isAprAgencyActivatedSubject: BehaviorSubject<AprActivatedResponse> = new BehaviorSubject<AprActivatedResponse>(null);
  public isAprAgencyActivated$: Observable<AprActivatedResponse> = this.isAprAgencyActivatedSubject.asObservable();

  private isIbanValidSubject: BehaviorSubject<IbanResponse> = new BehaviorSubject<IbanResponse>(null);
  public isIbanValid$: Observable<IbanResponse> = this.isIbanValidSubject.asObservable();

  private countriesSubject: BehaviorSubject<Dropdown[]> = new BehaviorSubject([]);
  readonly countries$: Observable<Dropdown[]> = this.countriesSubject.asObservable();

  private currenciesSubject: BehaviorSubject<Dropdown[]> = new BehaviorSubject([]);
  public currencies$: Observable<Dropdown[]> = this.currenciesSubject.asObservable();

  public countryList: Country[];
  public currencyList: Currency[];

  private isOfacRoutingSubject: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(null);
  public isOfacRouting$: Observable<Boolean> = this.isOfacRoutingSubject.asObservable();

  private isOfacSwiftSubject: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(null);
  public isOfacSwift$: Observable<Boolean> = this.isOfacSwiftSubject.asObservable();

  private termsAndConditionsSubject: BehaviorSubject<TermsAndConditions> = new BehaviorSubject<TermsAndConditions>(null);
  public termsAndConditionsInfo$: Observable<TermsAndConditions> = this.termsAndConditionsSubject.asObservable();

  private termsAndConditionsAdditionalSubject: BehaviorSubject<TermsAndConditions> = new BehaviorSubject<TermsAndConditions>(null);
  public termsAndConditionsAdditionalInfo$: Observable<TermsAndConditions> = this.termsAndConditionsAdditionalSubject.asObservable();

  private editAlertCodeSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public editAlertCode$: Observable<string> = this.editAlertCodeSubject.asObservable();

  private editAlertCompleteSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public editAlertComplete$: Observable<boolean> = this.editAlertCompleteSubject.asObservable();

  setGdsOk(value: boolean) {
    this.gdsOkSubject.next(value);
  }

  isPartnerIdCorrect(partnerId: string, affiliateIdHash: string): Observable<HttpResponse<PartnerResponse>> {
    const request = {
      hashCode: partnerId,
      affiliateId: affiliateIdHash,
    };

    this.spinnerService.setMessage('Checking ...');
    return this.httpClient
      .post<PartnerResponse>(Constants.URL_GET_PARTNER_ID, request, {observe: 'response'})
      .pipe(
        tap((data) => {

          const logServiceMessage = this.logService.getNewMessage();
          logServiceMessage.userId = localStorage.getItem(Constants.USER_ID);
          logServiceMessage.correlationId = data.headers.get('x-correlation-id');
          logServiceMessage.actionId = 'AUTHENTICATION';
          logServiceMessage.resourceId = 'PARTNER';
          logServiceMessage.message.custom.request = request;
          logServiceMessage.message.custom.response = data;

          if (data.body.hasError !== undefined) {
            logServiceMessage.logLevel = LogLevel.ERROR;
            logServiceMessage.userMetadata.custom.partnerName = 'UNDEFINED';
            logServiceMessage.message.text = 'Authentication Channel Partner Error';
            this.logService.sendMessage(logServiceMessage);
            this.router.navigate(['/not-found']);
          } else {
            this.gdsOkSubject.next(true);
            logServiceMessage.logLevel = LogLevel.INFO;
            logServiceMessage.message.text = 'Authentication Channel Partner Success';
            logServiceMessage.userMetadata.custom.partnerName = data.body.partnerName;
            this.logService.sendMessage(logServiceMessage);
            this.loadCountriesAPR();
            this.loadCurrenciesAPR();

          }
        })
      );
     // return this.httpClient.get<PartnerResponse>('http://localhost:3000/juniper.json');
  }

  getPartnerByNameAndVcc(partnerName: string, vccYn: string): Observable<HttpResponse<PartnerResponse>> {
    const request = {
      partnerName: partnerName,
      vccYn: vccYn,
    };

    this.spinnerService.setMessage('Checking ...');
    return this.httpClient
      .post<PartnerResponse>(Constants.URL_GET_PARTNER_BY_NAME_AND_VCC, request, {observe: 'response'})
      .pipe(
        tap((data) => {

          const logServiceMessage = this.logService.getNewMessage();
          logServiceMessage.userId = localStorage.getItem(Constants.USER_ID);
          logServiceMessage.correlationId = data.headers.get('x-correlation-id');
          logServiceMessage.actionId = 'SEARCH PARTNER VCC';
          logServiceMessage.resourceId = 'PARTNER';
          logServiceMessage.message.custom.request = request;
          logServiceMessage.message.custom.response = data;

          if (data.body.hasError !== undefined) {
            logServiceMessage.logLevel = LogLevel.ERROR;
            logServiceMessage.userMetadata.custom.partnerName = 'UNDEFINED';
            logServiceMessage.message.text = 'Search Channel Partner By Name and Vcc Error';
            this.logService.sendMessage(logServiceMessage);
            this.router.navigate(['/not-found']);
          } else {
            this.gdsOkSubject.next(true);
            logServiceMessage.logLevel = LogLevel.INFO;
            logServiceMessage.message.text = 'Search Channel Partner By Name and Vcc Success';
            logServiceMessage.userMetadata.custom.partnerName = data.body.partnerName;
            this.logService.sendMessage(logServiceMessage);
          }
        })
      );
     // return this.httpClient.get<PartnerResponse>('http://localhost:3000/juniper.json');
  }

  loadCountriesAPR(): void {

    const headerDict = {
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    const payload = {};
    const url = Constants.URL_GET_COUNTRIES;

    this.httpClient.post<Country[]>(url, payload, requestOptions)
      .subscribe(response => {
        const arrayDropdown: Dropdown[] = [];
        response.map(country => {
          if (country.ofacSanctioned === 'N') {
            arrayDropdown.push(new Dropdown(country.countryName, country.countryId));
          }
        });
        this.countriesSubject.next(arrayDropdown);
      });
  }

  loadCurrenciesAPR(): void {

    const headerDict = {
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    const payload = {};
    const url = Constants.URL_GET_CURRENCIES;

    this.httpClient.post<Currency[]>(url, payload, requestOptions)
      .subscribe(response => {
        const arrayDropdown: Dropdown[] = [];
        response.map(currency => {
          arrayDropdown.push(new Dropdown(currency.name, currency.code));
        });
        this.currenciesSubject.next(arrayDropdown);
      });
  }

  getCountryById(countryId: string): Dropdown {
    return (this.countriesSubject.value).find(i => i.code === countryId);
  }

  getAprAgencyInfo(aprAgencyInfo: AprAgencyInfo): Observable<AprAgencyInfo> {

    const headerDict = {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem(Constants.TOKEN_KEY),
    };

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.httpClient
      .post<AprAgencyInfo>(
        Constants.URL_GET_APR_AGENCY_INFO,
        {
          agencyCode: aprAgencyInfo.agencyCode,
          agencyIdType: aprAgencyInfo.agencyIdType,
          email: aprAgencyInfo.email,
          partnerId: aprAgencyInfo.partnerId,
          companyName: aprAgencyInfo.companyName,
        },
        requestOptions
      )
      .pipe(
        tap((response: AprAgencyInfo) => {
          this.aprAgencyInfoSubject.next(response);
          return response;
        })
      );
  }

  get aprAgencyInfo(): Observable<AprAgencyInfo> {
    return this.aprAgencyInfoSubject.asObservable();
  }

  isAprAgencyActivated(aprAgencyInfo: AprAgencyInfo): Observable<AprActivatedResponse> {

    const headerDict = {
      'Content-Type': 'application/json',
      'Authorization':  window.localStorage.getItem(Constants.TOKEN_KEY),
    };

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.httpClient
      .post<AprActivatedResponse>(
        Constants.URL_IS_APR_AGENCY_ACTIVATED, aprAgencyInfo, requestOptions
      )
      .pipe(
        tap((response: AprActivatedResponse) => {
          this.isAprAgencyActivatedSubject.next(response);
          return response;
        })
      );
  }

  saveStep(aprSave: AprSave): Observable<AprSave> {

    const headerDict = {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem(Constants.TOKEN_KEY),
    };

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.httpClient
      .post<AprSave>(Constants.URL_POST_APR_CREATE_REQUEST, aprSave, requestOptions)
      .pipe(
        tap((data) => {
          this.registrationIdSubject.next(data.registrationId);
        }
        ));

  }

  isIbanValid(iban: string, country: string, locale: string): Observable<IbanResponse> {

    const headerDict = {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem(Constants.TOKEN_KEY),
    };

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return (this.httpClient
      .post<IbanResponse>(
        Constants.URL_POST_IS_IBAN_VALID,
        {
          iban: iban ? iban.toUpperCase() : null,
          country: country ? country.toUpperCase() : null,
          locale: locale,
        },
        requestOptions
      )
      .pipe(
        tap((response: IbanResponse) => {
          this.isIbanValidSubject.next(response);
          return response;
        })
      ));
  }

  isOfacRouting(code: string): Observable<HttpResponse<Boolean>> {

    const headerDict = {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem(Constants.TOKEN_KEY),
    };

    return (this.httpClient
      .post<Boolean>(
        Constants.URL_POST_IS_OFAC_ROUTING_VALID,
        {
          code: code ? code.toUpperCase() : null,
        },
        {observe: 'response', headers: new HttpHeaders(headerDict)}
      )
      .pipe(
        tap((response: HttpResponse<Boolean>) => {
          try {
            localStorage.setItem(Constants.IS_OFAC_ROUTING_CORRELATION_ID, response.headers.get('x-correlation-id'));
          } catch (e) {
            localStorage.setItem(Constants.IS_OFAC_ROUTING_CORRELATION_ID, 'UNDEFINED' );
          }
          this.isOfacRoutingSubject.next(response.body);
          return response.body;
        })
      ));
  }


  isOfacSwift(code: string): Observable<HttpResponse<Boolean>> {
    const headerDict = {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem(Constants.TOKEN_KEY),
    };

    return (this.httpClient
      .post<Boolean>(
        Constants.URL_POST_IS_OFAC_SWIFT_VALID,
        {
          code: code ? code.toUpperCase() : null,
        },
        {observe: 'response', headers: new HttpHeaders(headerDict)}
      )
      .pipe(
        tap((response: HttpResponse<Boolean>) => {
          try {
            localStorage.setItem(Constants.IS_OFAC_SWIFT_CORRELATION_ID, response.headers.get('x-correlation-id'));
          } catch (e) {
            localStorage.setItem(Constants.IS_OFAC_SWIFT_CORRELATION_ID, 'UNDEFINED' );
          }
          this.isOfacRoutingSubject.next(response.body);
          return response.body;
        })
      ));
  }

  getTermsAndConditionsActive(): Observable<TermsAndConditions> {

    const headerDict = {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem(Constants.TOKEN_KEY),
    };

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.httpClient
      .post<TermsAndConditions>(
        Constants.URL_POST_GET_TERMS_AND_CONDITIONS_ACTIVE,
        { },
        requestOptions
      )
      .pipe(
        tap((response: TermsAndConditions) => {
          return response;
        })
      );
  }


  getTermsAndConditionsById(id: number): Observable<TermsAndConditions> {

    const headerDict = {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem(Constants.TOKEN_KEY),
    };

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.httpClient
      .post<TermsAndConditions>(
        Constants.URL_POST_GET_TERMS_AND_CONDITIONS_BY_ID,
        {id: id},
        requestOptions
      )
      .pipe(
        tap((response: TermsAndConditions) => {
          return response;
        })
      );
  }

  setTermsAndConditionsActive (tc: TermsAndConditions) {
    this.termsAndConditionsSubject.next(tc);
  }

  setTermsAndConditionsAdditional (tc: TermsAndConditions) {
    this.termsAndConditionsAdditionalSubject.next(tc);
  }

  setEditAlertCode (alertCode: string, alertComplete: boolean) {
    this.editAlertCodeSubject.next(alertCode);
    this.editAlertCompleteSubject.next(alertComplete);
  }

  upsertRegistration(aprSave: AprSave): Observable<String> {

    const headerDict = {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem(Constants.TOKEN_KEY),
    };

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.httpClient
      .post<String>(Constants.URL_POST_APR_UPSERT_REQUEST, aprSave, requestOptions)
      .pipe(
        tap((response) => {
          return response;
        }
        ));

  }

}
