export const environment = {
  production: true,
  name: 'prod',
  aprDomain: 'apr.onyxcentersource.com',
  aprServiceApiHost: 'apr.service.onyxcentersource.com',
  aprServiceApiUrl: 'https://apr.service.onyxcentersource.com/rest/public/',
  commonServiceApiUrl: 'https://ocd.service.onyxcentersource.com/api/geco/',
  cdnUrl: 'https://cdn.onyxcentersource.com/apr/',
  ga4TrackingId: 'G-6NKGGCEMST',
};
