import { FormControl } from '@angular/forms';
import { Constants } from '@app/app.constants';

export function latinPatternValidator(fc: FormControl) {

  let isValid = true;
  const regexpSwift = new RegExp(Constants.LATIN_PATTERN);

  if (fc.value) {
    isValid = regexpSwift.test(fc.value);
  }

  return isValid ? null : {
    latinPatternError: !isValid,
  };

}
