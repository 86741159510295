import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '@app/app.constants';
import { PartnerResponse } from '@app/models/partner-response.model';
import { AprService } from '@app/services/apr.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subscription, timer } from 'rxjs';
import { LogServiceClientService } from '@onyx/log-service-client-11';
import { environment } from 'environments/environment';
import { UUID } from 'angular2-uuid';
import { TermsAndConditions } from '@app/models/terms-and-conditions.model';

@Component({
  selector: 'ta-agency-registration-container',
  templateUrl: './agency-registration-container.component.html',
})
export class AgencyRegistrationContainerComponent implements OnInit, OnDestroy {

  showSessionExpiredPage: boolean;
  sessionExpiredTime: string;

  partnerId: string;
  partnerIdHash: string;
  affiliateIdHash: string;

  partnerIdInfo: PartnerResponse;

  editMode = false;

  subscription: Subscription;
  constructor(
    private jwtHelper: JwtHelperService,
    private aprService: AprService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private logService: LogServiceClientService
  ) { }

  ngOnInit(): void {
    this.subscription = new Subscription();
    this.showSessionExpiredPage = false;

    localStorage.removeItem(Constants.TOKEN_KEY);
    localStorage.setItem(Constants.USER_ID, UUID.UUID());
    localStorage.setItem(Constants.IS_OFAC_SWIFT_CORRELATION_ID, 'UNDEFINED');
    localStorage.setItem(Constants.IS_OFAC_ROUTING_CORRELATION_ID, 'UNDEFINED');

    this.logService.setEnvironment(environment.name);
    this.logService.setApplication(Constants.APR_APP, Constants.VERSION);

    this.logService.setApplicationCustom('build_number', Constants.VERSION_BUILD_NUMBER);
    this.logService.setApplicationCustom('build_date', Constants.VERSION_BUILD_DATE);
    this.logService.setApplicationCustom('branch_name', Constants.VERSION_BRANCH_NAME);
    this.logService.setApplicationCustom('last_commit', Constants.VERSION_LAST_COMMIT);

    this.subscription.add(
      this.activatedRoute.queryParams.subscribe(params => {
        if (this.router.url.indexOf('agency-registration') !== -1) {
          this.partnerIdHash = params['partnerId'];
          this.editMode = params['mode'] && params['mode'] === 'edit';

          if (this.partnerIdHash) {
            this.affiliateIdHash = params['affiliateId'];
            // Call service to check GDS
            this.aprService.isPartnerIdCorrect(this.partnerIdHash, this.affiliateIdHash).subscribe(partnerIdInfo => {
              const partnerIdInfoBody = partnerIdInfo.body;
              if (partnerIdInfoBody.hasError) {
                // show not found
                this.router.navigate(['/not-found']);
              } else {
                this.partnerIdInfo = partnerIdInfoBody;

                this.aprService.getTermsAndConditionsActive().subscribe(response => {
                  this.aprService.setTermsAndConditionsActive(response);
                });

                if (this.partnerIdInfo.tcAdditionalId) {
                  this.aprService.getTermsAndConditionsById(this.partnerIdInfo.tcAdditionalId).subscribe(response => {
                    this.aprService.setTermsAndConditionsAdditional(response);
                  });
                }
              }
            });
          } else {
            // show not found
            this.router.navigate(['/not-found']);
          }
        }
      })
    );

    this.subscription.add(
      timer(0, 1000).subscribe(() => {
        const token = localStorage.getItem(Constants.TOKEN_KEY);

        if (token && this.tokenExists(token)) {
          if (this.jwtHelper.isTokenExpired(token)) {
            this.showSessionExpiredPage = true;
            this.sessionExpiredTime = String(this.calculateExpirationTime());
          }
        }
      })
    );
  }

  calculateExpirationTime(): number {
    const decodedToken = this.jwtHelper.decodeToken(localStorage.getItem(Constants.TOKEN_KEY));
    if (decodedToken) {
      const minutesToExpire = decodedToken.exp - decodedToken.iat;
      return (minutesToExpire / 60);
    }
  }

  tokenExists(token: string): boolean {
    return token.indexOf('') !== -1;
  }

  moveToTop($event): void {
    const element = document.getElementById('top');
    if (element !== null) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  reloadAppComponent(): void {
    localStorage.removeItem(Constants.TOKEN_KEY);
    window.location.reload();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
