import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { AprService } from '@app/services/apr.service';
import { IbanResponse } from '@app/models/iban-response.model';
import { Observable } from 'rxjs';
import { debounceTime, map, switchMap, take } from 'rxjs/operators';
import { Constants } from '@app/app.constants';
export class IbanValidator {

  static createValidator(aprService: AprService, locale: string, country: string): AsyncValidatorFn {

    return (control: AbstractControl): Observable<ValidationErrors> => {
      return control.valueChanges.pipe(
        debounceTime(500),
        take(1),
        switchMap(_ =>
          aprService.isIbanValid(control.value, country, locale + '_' + getCodeI18n(locale)).pipe(
            map((response: IbanResponse) => {
              return response.isValid ? null : {
                ibanInvalid: true,
                errorIbanCountry: response.isValid ? null : response.errors.iban_country,
                errorNull: response.isValid ? null : response.errors.iban_null,
                errorLength: response.isValid ? null : response.errors.iban_length,
                errorMin: response.isValid ? null : response.errors.iban_min_length,
                errorMax: response.isValid ? null : response.errors.iban_max_length,
                errorFormatChar: response.isValid ? null : response.errors.iban_format_char,
                errorCheckDigit: response.isValid ? null : response.errors.iban_check_digit,
                errorConfigMissed: response.isValid ? null : response.errors.iban_config_missed,
                exception: response.errors.exception === undefined ? null : response.errors.exception,
              };
            })
          )
        )
      );
    };

    function getCodeI18n(code: string) {
      const languageList = Constants.APR_LANGUAGES;
      const lang = languageList.filter((x) => x.code === code);
      return lang.length > 0 ? lang[0].i18n : null;
    }
  }
}
