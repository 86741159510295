import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ta-agency-registration-complete',
  templateUrl: './agency-registration-complete.component.html',
})
export class AgencyRegistrationCompleteComponent implements OnInit {

  @Input() emailContact: String;

  constructor() { }

  ngOnInit() {
  }

}
