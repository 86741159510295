<div class="container-layout">
  <div class="container-fluid container-content">
    <div class="marquee">
      <i *ngIf="alertComplete" class="far fa-check marquee-icon"></i>
      <i *ngIf="!alertComplete" class="far fa-file-exclamation marquee-icon"></i>
      <h1 class="marquee-heading"> {{messageCode + '.header' | translate}}</h1>
      <h2 class="marquee-message">
        <p>
          {{messageCode + '.bodyText1' | translate: {agencyCode: agencyCode} }}
        </p>

        <p [innerHtml]="messageCode + '.bodyText2' | translate | replace: {URL_CUSTOMER_SUPPORT: customerSupportUrl}">
        </p>
      </h2>
      <p>
        {{'apr.registrationUpdateIdentity.closeWindow' | translate}}
      </p>
    </div>
  </div>
</div>

