<div (window:beforeunload)="onBeforeUnload($event)">
  <div *ngIf="!showReConfirmationPage && !showCompletePage && !showSessionExpiredPage && !showOfacPage" class="container-fluid container-content">
    <div class="row">
      <div class="offset-xl-3 col-xl-6 offset-lg-2 col-lg-8">
        <!--i18n-->
        <div class="offset-lg-10">
          <a class="nav-link dropdown-toggle text-right pr-0" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
            <i class="far fa-globe-americas"></i> {{activeLang}}
          </a>
          <div class="dropdown-menu">
            <div *ngFor="let item of languageList">
              <a class="dropdown-item" (click)="useLanguage(item.code, true)">{{item.name}}</a>
            </div>
          </div>
        </div>
        <!--i18n-->
      </div>
    </div>
    <div class="row">
      <div class="offset-xl-3 col-xl-6 offset-lg-2 col-lg-8">
        <div class="card">
          <div class="card-header">
            <h2 class="card-title">{{'apr.gettingStarted.header' | translate}}</h2>
          </div>
          <div class="card-body">
            <p>
              {{'apr.gettingStarted.bodyText1' | translate | replace: { partnerName: partnerName } }}
            </p>
            <p>
              {{'apr.gettingStarted.bodyText2' | translate}}
            </p>
            <p class="mb-0">
              {{'apr.gettingStarted.b3_1' | translate}}
              <a target=_blank href="{{this.userGuideLink}}" (click)="sendGAInfo('g')">{{'apr.gettingStarted.b3_2' | translate}}<i class='far fa-external-link'></i></a>
              {{'apr.gettingStarted.b3_2_1' | translate}}
              <a target=_blank href="{{this.needAssitanceLink}}">{{'apr.gettingStarted.b3_3' | translate}}<i class='far fa-external-link'></i></a>
            </p>
          </div>
          <div *ngIf="this.faqLink" class="card-footer">
            <span [innerHTML]="'apr.gettingStarted.faq' | translate"></span><a target=_blank href="{{this.faqLink}}" (click)="sendGAInfo('f')">{{'apr.gettingStarted.faqLink' | translate}}<i class='far fa-external-link'></i></a>
            {{'apr.gettingStarted.faqForAwnswer' | translate}}
          </div>
        </div>
        <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">

          <ta-agency-registration-identity-dynamic
            id="step1card"
            [registrationForm]="registrationForm"
            [partnerIdInfo]="partnerIdInfo"
            (toStep2Emit)="moveToStep2()"
            (toStep3Emit)="moveToStep3($event)"
            (showReconfirmationPage)="showReconfirmationPage()"
            (showOfacPage)="setShowOfacPage()"
            (emailContact)= "setEmailContact($event)"
            (aprSave)="setAprSave($event)"
            (termsAndConditionsSignedEmit)="setTermsAndConditionsSigned($event)"
            (termsAndConditionsAdditionalSignedEmit)="setTermsAndConditionsAdditionalSigned($event)"
            >
          </ta-agency-registration-identity-dynamic>

          <ta-agency-registration-details
            #step2card
            id="step2card"
            *ngIf="toStep2"
            [registrationForm]="registrationForm"
            [partnerIdInfo]="partnerIdInfo"
            [registrationId]="registrationId"
            [termsAndConditionsSigned]="termsAndConditionsSigned"
            [termsAndConditionsAdditionalSigned]="termsAndConditionsAdditionalSigned"
            (toStep3Emit)="moveToStep3($event)"
            (aprSave)="setAprSave($event)">
          </ta-agency-registration-details>

          <ta-agency-registration-payment
            #step3card
            id="step3card"
            *ngIf="toStep3"
            [registrationForm]="registrationForm"
            [partnerId]="partnerId"
            [partnerName]="partnerName"
            [registrationId]="registrationId"
            [aprSave]="aprSave"
            [showFormPayment]="showFormPayment"
            [termsAndConditionsSigned]="termsAndConditionsSigned"
            [termsAndConditionsAdditionalSigned]="termsAndConditionsAdditionalSigned"
            (showCompletePage)="setShowCompletePage()"
            (toStep3Emit)="moveToStep3($event)">
          </ta-agency-registration-payment>

        </form>

      </div>
    </div>
  </div>
</div>

<!-- Reconfirmation Page -->
<div *ngIf="showReConfirmationPage && !showSessionExpiredPage">
  <ta-agency-registration-reconfirmation></ta-agency-registration-reconfirmation>
</div>

<!-- Complete Page -->
<div *ngIf="showCompletePage && !showSessionExpiredPage">
  <ta-agency-registration-complete [emailContact]="emailContact"></ta-agency-registration-complete>
</div>

<!-- Session Expiration Page -->
<div *ngIf="showSessionExpiredPage">
  <ta-agency-registration-session-expiration
    [partnerIdInfo]="partnerIdInfo"
    [sessionExpiredTime]="sessionExpiredTime"
    (reloadRegistration)="reloadRegistration()">
  </ta-agency-registration-session-expiration>
</div>

<!-- Reconfirmation Ofac Page -->
<div *ngIf="showOfacPage && !showSessionExpiredPage">
  <ta-agency-registration-ofac></ta-agency-registration-ofac>
</div>