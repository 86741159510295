import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Constants } from '@app/app.constants';
import { AprAgencyInfo } from '@app/models/apr-agency-info.model';
import { AprSave } from '@app/models/apr-save.model';
import { PartnerResponse } from '@app/models/partner-response.model';
import { TermsAndConditionsSigned } from '@app/models/terms-and-conditions-signed.model';
import { AprService } from '@app/services/apr.service';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';

import { CommonDataService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { latinPatternValidator } from '@shared/directives/latin-pattern-validator';
import { Country, Dropdown } from '@shared/models';
import { Observable } from 'rxjs';


@Component({
  selector: 'ta-agency-registration-details',
  templateUrl: './agency-registration-details.component.html',
})
export class AgencyRegistrationDetailsComponent implements OnInit, AfterViewInit {

  @Input() registrationForm: FormGroup;
  @Input() partnerIdInfo: PartnerResponse;
  @Input() registrationId: number;
  @Input() termsAndConditionsSigned: TermsAndConditionsSigned;
  @Input() termsAndConditionsAdditionalSigned: TermsAndConditionsSigned;


  @ViewChild('companyNameInput') companyNameInputElement: ElementRef;
  @ViewChild('contactNameInput') contactNameInputElement: ElementRef;


  @Output() toStep3Emit: EventEmitter<any> = new EventEmitter();
  @Output() aprSave: EventEmitter<any> = new EventEmitter();


  detailsForm: FormGroup;
  public isValidating = false;
  public isComplete = false;
  public autofill = false;
  isInvalid = false;
  hasStates = false;
  countryList: Country[];
  states$: Observable<Dropdown[]>;
  countries$ = this.aprService.countries$;
  stateName: string;
  stateCode: string;
  askPaymentDetails = false;
  localData: AprAgencyInfo;
  isProfileFormFilled = false;

  public startTime: number;
  public stopTime: number;


  constructor(
    private fb: FormBuilder,
    private commonDataService: CommonDataService,
    private aprService: AprService,
    private translate: TranslateService,
    private gaService: GoogleAnalyticsService
  ) {

  }

  ngOnInit() {
    this.initForm();

    this.aprService.aprAgencyInfo.subscribe(data => {
      if (data) {
        // Autofill
        this.askPaymentDetails = data.askPaymentDetails;
        this.localData = data;

        if (!data.askProfileDetails) {
          // read-only card must be shown
          this.isComplete = true;

          // show complete card
          this.patchValues(data);

          if (data.askPaymentDetails) {
            // move to step 3 with show empty form
            this.toStep3Emit.emit(true);
          } else {
            // move to step 3 and show message in payment card
            this.toStep3Emit.emit(false);
          }

          this.aprService.saveStep(this.transformModel(data)).subscribe(response => {
            this.aprSave.emit(response);
          });

        }
      }
    });
  }


  public ngAfterViewInit(): void {
    if (this.companyNameInputElement !== undefined) {
      this.companyNameInputElement.nativeElement.focus();
    } else {
      if (this.contactNameInputElement !== undefined) {
        this.contactNameInputElement.nativeElement.focus();
      }
    }
  }

  initForm(): void {
    this.startTime = performance.now();
    this.gaService.startProfileCard(this.startTime);

    // No autofill
    if (this.partnerIdInfo && !this.partnerIdInfo.details) {
      this.detailsForm = this.fb.group({
        // Fields formControlName and validators
        companyName: ['' , [Validators.required, Validators.maxLength(80), latinPatternValidator]],
        contactName: ['', [Validators.required, latinPatternValidator]],
        selectedCountry: [null, [Validators.required]],
        address: ['', [Validators.required, Validators.maxLength(80), latinPatternValidator]],
        city: ['', [Validators.required, Validators.maxLength(80), latinPatternValidator]],
        zipCode: ['', [Validators.required, Validators.maxLength(10), latinPatternValidator]],
      });
    } else {
      this.autofill = true;
      this.detailsForm = this.fb.group({
        // Fields formControlName and validators
        companyName: ['' , null],
        contactName: ['', [Validators.required, latinPatternValidator]],
        selectedCountry: [null, [Validators.required]],
        address: ['', [Validators.required, Validators.maxLength(80), latinPatternValidator]],
        city: ['', [Validators.required, Validators.maxLength(80), latinPatternValidator]],
        zipCode: ['', [Validators.required, Validators.maxLength(10), latinPatternValidator]],
      });
    }



    if (this.registrationForm) {
      this.registrationForm.addControl('detailsForm', this.detailsForm);
    }
  }

  patchValues(data: AprAgencyInfo): void {
    this.companyName.patchValue(data.companyName);
    this.contactName.patchValue(data.contactName);
    this.selectedCountry.patchValue(this.aprService.getCountryById(data.country));
    this.onCountryChange(data.state);
    this.address.patchValue(data.address);
    this.city.patchValue(data.city);
    this.zipCode.patchValue(data.postalCode);
  }

  isFieldValid(field: string) {
    return this.detailsForm.get(field).touched && this.detailsForm.get(field).errors;
  }

  isFormValid() {
    return !this.detailsForm.valid && this.detailsForm.touched;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: false });
        this.isInvalid = true;
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public hasCountrySelection(): boolean {
    return this.selectedCountry.value !== null;
  }

  onCountryChange(stateId?: string) {
    if (this.commonDataService.hasCountryStates(this.selectedCountry.value.code)) {
      this.detailsForm.addControl('selectedState', new FormControl(null, [Validators.required]));
      this.states$ = this.commonDataService.loadStatesByCountryAPR(this.selectedCountry.value.code);
      this.commonDataService.states$.subscribe(() => {
        this.selectedState.patchValue(this.commonDataService.getStateById(stateId));
      });

      this.hasStates = true;

    } else {
      this.hasStates = false;
      if (this.detailsForm.get('selectedState')) {
        this.detailsForm.removeControl('selectedState');
      }
    }
  }

  compareFn(d1: Dropdown, d2: Dropdown): boolean {
    return d1 && d2 ? d1.code === d2.code && d1.name === d2.name : d1 === d2;
  }


  onContinueClicked(): void {

    if (this.detailsForm.valid) {
      // check validations
      this.isValidating = true;
      this.isComplete = true;
      this.detailsForm.markAsUntouched();
      this.detailsForm.disable();

      this.aprService.saveStep(this.transformModel(this.localData)).subscribe(data => {
        this.aprSave.emit(data);
      });

      if (this.askPaymentDetails) {
        // move to step 3 with show empty form
        this.toStep3Emit.emit(true);
      } else {
        // move to step 3 and show message in payment card
        this.toStep3Emit.emit(false);
      }

      this.stopTime = performance.now();
      this.gaService.finishProfileCard(this.stopTime);
      this.gaService.spentTimeProfileCard(this.stopTime - this.startTime);

    } else {
      this.validateAllFormFields(this.detailsForm);
    }
  }

  transformModel(data?: AprAgencyInfo): AprSave {

    if (data.askProfileDetails) {
      this.isProfileFormFilled = true;
    }

    // Necessary logic for state
    if (data && data.state) {
      this.stateName = data.state;
    } else if (this.hasStates) {
      this.stateName = this.selectedState.value.name;
      this.stateCode = this.selectedState.value.code;
    } else {
      this.stateName = '';
      this.stateCode = '';
    }

    const identifyForm = this.registrationForm.get('identityForm');

    const save: AprSave = {
      registrationId: this.registrationId,
      partnerId: this.partnerIdInfo.partnerId,
      otaId: this.partnerIdInfo.otaId,
      status: this.registrationId === null ? `${Constants.STEP_REGISTRATION}, ${Constants.STEP_PROFILE}` : Constants.STEP_PROFILE,
      registrationDetails: {
        sourceId: this.partnerIdInfo.partnerId,
        officeId: identifyForm.get('officeId') ? identifyForm.get('officeId').value : null,
        agencyType: identifyForm.get('agencyIdType') ? identifyForm.get('agencyIdType').value.code : null,
        agencyCode: identifyForm.get('noIataCheck') ? null : identifyForm.get('agencyId') ? identifyForm.get('agencyId').value : null,
        email: identifyForm.get('email').value,
        termsAndConditions: identifyForm.get('privacyCheck').value,
        marketingNewsletter: identifyForm.get('communicationEmailCheck').value,
        noIataCheck: identifyForm.get('noIataCheck') ? identifyForm.get('noIataCheck').value : null,
        termsAndConditionsSigned: {
          id: null,
          ipAddress: null,
          timestamp: null,
        },
        termsAndConditionsAdditionalSigned: {
          id: null,
          ipAddress: null,
          timestamp: null,
        },
      },
      profileDetails: {
        member: data.member,
        companyName: data.askProfileDetails ? this.companyName.value : data.companyName,
        contactName: data.askProfileDetails ? this.contactName.value : data.contactName,
        country: data.askProfileDetails ? this.selectedCountry.value.code : data.country,
        address: data.askProfileDetails ? this.address.value : data.address,
        city: data.askProfileDetails ? this.city.value : data.city,
        state: data.askProfileDetails ? this.stateCode : data.state,
        postalCode: data.askProfileDetails ? this.zipCode.value : data.postalCode,
        askPaymentDetails: data.askPaymentDetails,
        askProfileDetails: data.askProfileDetails,
        existsMainContact: data.existsMainContact,
        existsPortalUser: data.existsPortalUser,
        isPortalUser: data.isPortalUser,
        isMainContact: data.isMainContact,
        payLoc: data.payLoc,
        isPortalUserOfAnyEntity: data.isPortalUserOfAnyEntity,
        isOfacSanctioned: data.isOfacSanctioned,
      },
      paymentDetails: {
        paymentMethod: data.askProfileDetails ? '' : data.paymentMethod,
        currency: data.askProfileDetails ? '' : data.currency,
        bankName: data.askProfileDetails ? '' : data.bankName,
        bankCountry: data.askProfileDetails ? '' : data.bankCountry,
        bankAddress: data.askProfileDetails ? '' : data.bankAddress,
        bankAccountName: data.askProfileDetails ? '' : data.bankAccountName,
        bankCity: data.askProfileDetails ? '' : data.bankCity,
        bankState: data.askProfileDetails ? '' : data.bankState,
        bankPostal: data.askProfileDetails ? '' : data.bankPostal,
        accountHolderName: data.askProfileDetails ? '' : data.accountHolderName,
        bankCode: data.askProfileDetails ? '' : data.bankCode,
        bankBranchCode: data.askProfileDetails ? '' : data.bankBranchCode,
        accountNumber: data.askProfileDetails ? '' : data.accountNumber,
        swift: data.askProfileDetails ? '' : data.swift,
        iban: data.askProfileDetails ? '' : data.iban,
        accountSuffix: data.askProfileDetails ? '' : data.accountSuffix,
        accountType: data.askProfileDetails ? '' : data.accountType,
        bankTransitNumber: data.askProfileDetails ? '' : data.bankTransitNumber,
        achRouting: data.askProfileDetails ? '' : data.achRouting,
        branchCode: data.askProfileDetails ? '' : data.branchCode,
        branchNumber: data.askProfileDetails ? '' : data.branchNumber,
        bsbCode: data.askProfileDetails ? '' : data.bsbCode,
        clabe: data.askProfileDetails ? '' : data.clabe,
        routingCode: data.askProfileDetails ? '' : data.routingCode,
      },
      lang: this.translate.currentLang,
      isMultiAid: this.partnerIdInfo.isMultiAid ? this.partnerIdInfo.isMultiAid : false,
    };

    // read-only fields
    if (this.partnerIdInfo && this.partnerIdInfo.details) {
      save.registrationDetails.officeId = this.partnerIdInfo.details.uniqueId;
      save.registrationDetails.agencyCode = data.agencyCode;
      save.registrationDetails.agencyType = data.agencyIdType;
      save.profileDetails.companyName = this.partnerIdInfo.details.clientName;
      this.companyName.setValue(this.partnerIdInfo.details.clientName);
    }

    if (this.termsAndConditionsSigned) {
      save.registrationDetails.termsAndConditionsSigned.id = this.termsAndConditionsSigned.id;
      save.registrationDetails.termsAndConditionsSigned.ipAddress = this.termsAndConditionsSigned.ipAddress;
      save.registrationDetails.termsAndConditionsSigned.timestamp = this.termsAndConditionsSigned.timestamp;
    }

    if (this.termsAndConditionsAdditionalSigned) {
      save.registrationDetails.termsAndConditionsAdditionalSigned.id = this.termsAndConditionsAdditionalSigned.id;
      save.registrationDetails.termsAndConditionsAdditionalSigned.ipAddress = this.termsAndConditionsAdditionalSigned.ipAddress;
      save.registrationDetails.termsAndConditionsAdditionalSigned.timestamp = this.termsAndConditionsAdditionalSigned.timestamp;
    }

    this.aprSave.emit(save);

    return save;
  }

  public sendGAInfo (field: any, key: string) {
    if (this.detailsForm.get(field).errors) {
      this.gaService.frontendValidationError(field, key);
    }
  }

  get companyName() { return this.detailsForm.get('companyName'); }
  get contactName() { return this.detailsForm.get('contactName'); }
  get selectedCountry() { return this.detailsForm.get('selectedCountry'); }
  get address() { return this.detailsForm.get('address'); }
  get city() { return this.detailsForm.get('city'); }
  get selectedState() { return this.detailsForm.get('selectedState'); }
  get zipCode() { return this.detailsForm.get('zipCode'); }

}
