import { Component, Input, OnInit } from '@angular/core';
import { Constants } from '@app/app.constants';
import { AprSave } from '@app/models/apr-save.model';
import { AprService } from '@app/services/apr.service';

@Component({
  selector: 'ta-agency-edit-alert',
  templateUrl: './agency-edit-alert.component.html',
})
export class AgencyEditAlertComponent implements OnInit {

  @Input() agencyCode: string;

  public readonly customerSupportUrl = Constants.URL_CUSTOMER_SUPPORT;

  messageCode: string;
  alertComplete: boolean;

  constructor(
    private aprService: AprService
  ) { }

  ngOnInit(): void {
    this.aprService.editAlertCode$.subscribe(message => {
      this.messageCode = message;
    });

    this.aprService.editAlertComplete$.subscribe(response => {
      this.alertComplete = response;
    });
  }

}
