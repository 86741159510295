import { Injectable } from '@angular/core';
import { Message } from '@shared/models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessagesUtilsService {

  private messages = new BehaviorSubject<Message[]>([]);
  messageList = this.messages.asObservable();

  constructor() { }

  addMessage(message: Message) {
    if (!message.icon) {
      message.icon = this.getIcon(message.type);
    }
    const currentValue = this.messages.value;
    const updateValue = [...currentValue, message];
    this.messages.next(updateValue);
  }

  removeMessage(message: Message): void {
    const messageIndex: number = this.messages.value.indexOf(message);

    if (messageIndex >= 0) {
      this.messages.value.splice(messageIndex, 1);
    }
  }

  clearMessages(): void {
    this.messages.value.splice(0, this.messages.value.length);
  }

  getIcon(type: string) {
    let icon: string;
    switch (type) {
      case 'danger': icon = 'fa-exclamation-triangle'; break;
      case 'warning': icon = 'fa-hand-paper'; break;
      case 'info': icon = 'fa-pennant'; break;
      case 'success': icon = 'fa-check'; break;
    }
    return icon;
  }

}
